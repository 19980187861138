<template>
  <v-app style="background: linear-gradient(to left, rgb(100, 181, 246), rgb(144, 202, 249));">
   <p> <strong>Article 1 GENERAL PROVISIONS </strong></p>
<p>1. These Rules and Regulations (hereinafter: The Rules and Regulations) apply to the
terms of use of the tool that allows sending SMS text messages reminding of
appointments (hereinafter: the Add-on), being an add-on to the Google Calendar
application (hereinafter: The Application).</p>
<p>2. The Rules and Regulations have been drawn up on the basis of generally applicable
provisions of Polish law, including the Act of 18 July 2002 on the provision of
electronic services and constitute the regulations for the provision of electronic
services within the meaning of Art. 8 of the said Act.</p>
<p>3. The provision of services via the Add-on shall be based on the Rules and Regulations
and generally applicable provisions of Polish law.</p>
<p>4. Before first use of the Add-on, the User is obliged to read these Rules and
Regulations. The User may start using the Add-on on the condition that he has read
and accepted these Rules and Regulations.</p>
<p>5. The entity providing the service via the Add-on and the Controller within the meaning
of the Rules and Regulations shall be Michał Górski conducting business activity
under the business name ADG Michał Górski, at the address: ul. Lipowa 16D/U5, 81-
572 Gdynia, Poland TAX ID No. 9570949817, Statistical No. (REGON): 220563135 .</p>
<p><strong>Article 2 DEFINITIONS</strong></p>
The terms contained in these Rules and Regulations shall have the following meaning:
<p>1. Administrator – the entity providing the service via the Add-on and the controller of
personal data;</p>
<p>2. User – a person using the Add-on;</p>
<p>3. Account – User's account on the Google portal, allowing the use of the Add-on after
its installation;</p>
<p>4. Plan – one of the monthly subscription plans selected by the User as part of the
Add-on, allowing the use of the Add-on as part of the functionality covered by a given
plan;</p>
<p>5. Billing Period – a billing period of one month for a given Plan;</p>
<p>6. Stripe – an online payment platform managed by Stripe Inc. having its registered
office in San Francisco (USA) and Dublin (Ireland)</p>
<p><strong>Article 3 Rules for using the ADD-ON</strong></p>
<p>1. The principal functionality of the Add-on is sending SMS text messages reminding
about appointments marked in the Application.</p>
<p>2. The use of the Add-on shall commence (the conclusion of an agreement for the
provision of electronic services in the scope of using the Add-on) by installing the
Add-on, accepting the Rules and Regulations, selecting a Plan and (except for the Free
Plan and the two-week trial period) paying for the Plan for a given Billing Period(s).</p>
<p>3. The use of the Add-on shall be discontinued by terminating the Plan (the termination
of the agreement for the provision of electronic services) in accordance with the
subscription held and paid for (except for the Free Plan), or by suspending or deleting
the Account.</p>
<p>4. The use of the Add-on shall last for a period of time specified in accordance with the
terms and conditions applicable to a given Plan, subject to the suspension or deletion
of the Account.</p>
<p><strong>Article 4 HOW to use the Add-on</strong></p>
<p>1. In order to use the Add-on, it is necessary to install the Add-on on the User's device
and log in via the Account.</p>
<p>2. After logging in to the Add-on using the Account, the User will gain access to the
administrator's panel allowing the User to manage the services offered as part of the
Add-on, including the selection of the Plan (hereinafter: The Panel).</p>
<p>3. The Suspension or deletion of the Account shall make the use of the Add-on
impossible.</p>
<p>4. The User can choose one of the following Plans:<br>
a. Free;<br>
b. Standard;<br>
c. Premium;<br>
d. Business.</p>
<p>5. The fee for services provided under a given Plan is indicated in the Panel. The User
selects a given Plan for a given Billing Period(s) in the Panel.</p>
<p>6. A given Plan from those indicated in sec. 4(b)-(d) above may be used on the condition
of the prior payment of the subscription relevant to the given Plan, subject to sec. 10
below.</p>
<p>7. In the case of failure to pay, it is impossible to use a given Plan from those indicated
in sec. 4(b)-(d) above and in such a situation the User may only use the Free Plan.</p>
<p>8. The User may select a different Plan for a consecutive Billing Period during a given
Billing Period that has been paid for (subject to the Free Plan); such change of Plan
shall come into force on the day of the beginning of the new Billing Period.</p>
<p>9. In the case that a given Plan from those indicated in sec. 4(b)-(d) above is paid for in
advance for a period of 12 (twelve) Billing Periods, the User shall receive a discount of
20% on the entire above-mentioned subscription.</p>
<p>10. The User has the right to a one-time two-week free trial period (hereinafter: the Trial)
before using the Plan indicated in sec. 4(b) above. The Trial takes place during the
period indicated in the preceding sentence on the terms and conditions applicable to
the Plan indicated in section 4(b) above.</p>
<p>11. All payments made by the User for the use of a given Plan are handled by Stripe.</p>
<p>12. The User who sent the SMS text message is solely responsible for the content of the
SMS text messages sent via the Add-on. The User shall also be responsible that the
recipient of SMS messages sent via the Add-on has consented to receive them.</p>
<p>13. It is forbidden to post or send any unlawful content or unsolicited commercial
information via the Add-on.</p>
<p>14. Any actions that may cause difficulties or destabilization of the Add-on are
prohibited, in particular, the creation of bots and other such programs for spamming,
unsolicited marketing and other such activities that violate the law or rules of social
life.</p>
<p><strong>Article 5 technical conditions for using the Service</strong></p>
<p>The basis for using the Add-on in terms of technical requirements is that the User has a
device that allows access to the Internet, including a program for browsing Internet
resources (web browser) that accepts cookies, having an Account, as well as installing the
Add-on and the Application.</p>
<p><strong>Article 6 COMPLAINT PROCEDURE</strong></p>
<p>1. Any irregularities in the functioning of the Add-on may be the subject of a complaint.</p>
<p>2. Complaints should be submitted to the Administrator by e-mail to the following
address:support@notifyapp.cloud or by post to the following address: ADG Michał
Górski, ul. Lipowa 16D/U5, 81-572 Gdynia, Poland</p>
<p>3. Complaints will be considered by the Administrator within 14 days from the date of
submitting the complaint.</p>
<p><strong>Article 7 FINAL PROVISIONS</strong></p>
<p>1. The Rules and Regulations are available on the Internet at the following address:
notifyapp.cloud.</p>
<p>2. The Administrator shall make these Rules and Regulations available to the User at the
User's request in a way that allows the acquisition, reproduction and recording of the
content of the Rules and Regulations via the Internet.</p>
<p>3. The Administrator shall have the right to amend these Rules and Regulations at any
time for important reasons. The User will be notified of any amendment via email.
The amendment shall come into force within seven days from email.</p>
<p>4. If any provision of these Rules and Regulations should be found by an adjudicating
body to be invalid or ineffective, the remaining provisions of the Rules and
Regulations shall remain in force.</p>
<p>5. The rules for the processing of personal data are set out in the privacy policy, which
can be found at the following address: https://notifyapp.cloud/pdf/privacy_policy.pdf</p>
<p>6. These Rules and Regulations constitute a work within the meaning of the Act of 4
February 1994 on copyright and related rights (consolidated texts: Journal of Laws
2000.80.904, Journal of Laws 2006.90.631) and is subject to legal protection provided
for in the provisions of the above act.</p>
  </v-app>
</template>

<script>
//import firebase from 'firebase/compat/app';

export default {
  data() {
  },
  methods: {
  },
}
</script>
