const list = {
    AF: { name: 'Afghanistan (‫افغانستان‬‎)', code: '93', alphaId: false },
    AL: { name: 'Albania (Shqipëri)', code: '355', alphaId: true },
    DZ: { name: 'Algeria (‫الجزائر‬‎)', code: '213', alphaId: true },
    AS: { name: 'American Samoa', code: '1', alphaId: true },
    AD: { name: 'Andorra', code: '376', alphaId: true },
    AO: { name: 'Angola', code: '244', alphaId: true },
    AI: { name: 'Anguilla', code: '1', alphaId: true },
    AG: { name: 'Antigua and Barbuda', code: '1', alphaId: true },
    AR: { name: 'Argentina', code: '54', alphaId: false },
    AM: { name: 'Armenia (Հայաստան)', code: '374', alphaId: false },
    AW: { name: 'Aruba', code: '297', alphaId: true },
    AC: { name: 'Ascension Island', code: '247', alphaId: false },
    AU: { name: 'Australia', code: '61', alphaId: false },
    AT: { name: 'Austria (Österreich)', code: '43', alphaId: true },
    AZ: { name: 'Azerbaijan (Azərbaycan)', code: '994', alphaId: true },
    BS: { name: 'Bahamas', code: '1', alphaId: false },
    BH: { name: 'Bahrain (‫البحرين‬‎)', code: '973', alphaId: true },
    BD: { name: 'Bangladesh (বাংলাদেশ)', code: '880', alphaId: true },
    BB: { name: 'Barbados', code: '1', alphaId: true },
    BY: { name: 'Belarus (Беларусь)', code: '375', alphaId: false },
    BE: { name: 'Belgium (België)', code: '32', alphaId: false },
    BZ: { name: 'Belize', code: '501', alphaId: true },
    BJ: { name: 'Benin (Bénin)', code: '229', alphaId: false },
    BM: { name: 'Bermuda', code: '1', alphaId: true },
    BT: { name: 'Bhutan (འབྲུག)', code: '975', alphaId: true },
    BO: { name: 'Bolivia', code: '591', alphaId: true },
    BA: {
      name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
      code: '387',
      alphaId: true
    },
    BW: { name: 'Botswana', code: '267', alphaId: true },
    BR: { name: 'Brazil (Brasil)', code: '55', alphaId: false },
    IO: {
      name: 'British Indian Ocean Territory',
      code: '246',
      alphaId: false
    },
    VG: { name: 'British Virgin Islands', code: '1', alphaId: false },
    BN: { name: 'Brunei', code: '673', alphaId: true },
    BG: { name: 'Bulgaria (България)', code: '359', alphaId: true },
    BF: { name: 'Burkina Faso', code: '226', alphaId: true },
    BI: { name: 'Burundi (Uburundi)', code: '257', alphaId: true },
    KH: { name: 'Cambodia (កម្ពុជា)', code: '855', alphaId: true },
    CM: { name: 'Cameroon (Cameroun)', code: '237', alphaId: false },
    CA: { name: 'Canada', code: '1', alphaId: false },
    CV: { name: 'Cape Verde (Kabu Verdi)', code: '238', alphaId: true },
    BQ: { name: 'Caribbean Netherlands', code: '599', alphaId: false },
    KY: { name: 'Cayman Islands', code: '1', alphaId: false },
    CF: {
      name: 'Central African Republic (République centrafricaine)',
      code: '236',
      alphaId: true
    },
    TD: { name: 'Chad (Tchad)', code: '235', alphaId: true },
    CL: { name: 'Chile', code: '56', alphaId: false },
    CN: { name: 'China (中国)', code: '86', alphaId: false },
    CX: { name: 'Christmas Island', code: '61', alphaId: false },
    CC: { name: 'Cocos (Keeling) Islands', code: '61', alphaId: false },
    CO: { name: 'Colombia', code: '57', alphaId: false },
    KM: { name: 'Comoros (‫جزر القمر‬‎)', code: '269', alphaId: true },
    CD: {
      name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
      code: '243',
      alphaId: false
    },
    CG: {
      name: 'Congo (Republic) (Congo-Brazzaville)',
      code: '242',
      alphaId: true
    },
    CK: { name: 'Cook Islands', code: '682', alphaId: true },
    CR: { name: 'Costa Rica', code: '506', alphaId: false },
    CI: { name: 'Côte d’Ivoire', code: '225', alphaId: false },
    HR: { name: 'Croatia (Hrvatska)', code: '385', alphaId: true },
    CU: { name: 'Cuba', code: '53', alphaId: false },
    CW: { name: 'Curaçao', code: '599', alphaId: false },
    CY: { name: 'Cyprus (Κύπρος)', code: '357', alphaId: true },
    CZ: {
      name: 'Czech Republic (Česká republika)',
      code: '420',
      alphaId: false
    },
    DK: { name: 'Denmark (Danmark)', code: '45', alphaId: true },
    DJ: { name: 'Djibouti', code: '253', alphaId: true },
    DM: { name: 'Dominica', code: '1', alphaId: true },
    DO: {
      name: 'Dominican Republic (República Dominicana)',
      code: '1',
      alphaId: false
    },
    EC: { name: 'Ecuador', code: '593', alphaId: false },
    EG: { name: 'Egypt (‫مصر‬‎)', code: '20', alphaId: false },
    SV: { name: 'El Salvador', code: '503', alphaId: false },
    GQ: {
      name: 'Equatorial Guinea (Guinea Ecuatorial)',
      code: '240',
      alphaId: true
    },
    ER: { name: 'Eritrea', code: '291', alphaId: false },
    EE: { name: 'Estonia (Eesti)', code: '372', alphaId: true },
    SZ: { name: 'Eswatini', code: '268', alphaId: false },
    ET: { name: 'Ethiopia', code: '251', alphaId: false },
    FK: {
      name: 'Falkland Islands (Islas Malvinas)',
      code: '500',
      alphaId: true
    },
    FO: { name: 'Faroe Islands (Føroyar)', code: '298', alphaId: true },
    FJ: { name: 'Fiji', code: '679', alphaId: true },
    FI: { name: 'Finland (Suomi)', code: '358', alphaId: true },
    FR: { name: 'France', code: '33', alphaId: true },
    GF: {
      name: 'French Guiana (Guyane française)',
      code: '594',
      alphaId: false
    },
    PF: {
      name: 'French Polynesia (Polynésie française)',
      code: '689',
      alphaId: true
    },
    GA: { name: 'Gabon', code: '241', alphaId: true },
    GM: { name: 'Gambia', code: '220', alphaId: true },
    GE: { name: 'Georgia (საქართველო)', code: '995', alphaId: true },
    DE: { name: 'Germany (Deutschland)', code: '49', alphaId: true },
    GH: { name: 'Ghana (Gaana)', code: '233', alphaId: false },
    GI: { name: 'Gibraltar', code: '350', alphaId: true },
    GR: { name: 'Greece (Ελλάδα)', code: '30', alphaId: true },
    GL: { name: 'Greenland (Kalaallit Nunaat)', code: '299', alphaId: true },
    GD: { name: 'Grenada', code: '1', alphaId: true },
    GP: { name: 'Guadeloupe', code: '590', alphaId: true },
    GU: { name: 'Guam', code: '1', alphaId: false },
    GT: { name: 'Guatemala', code: '502', alphaId: true },
    GG: { name: 'Guernsey', code: '44', alphaId: true },
    GN: { name: 'Guinea (Guinée)', code: '224', alphaId: false },
    GW: { name: 'Guinea-Bissau (Guiné Bissau)', code: '245', alphaId: false },
    GY: { name: 'Guyana', code: '592', alphaId: true },
    HT: { name: 'Haiti', code: '509', alphaId: true },
    HN: { name: 'Honduras', code: '504', alphaId: true },
    HK: { name: 'Hong Kong (香港)', code: '852', alphaId: false },
    HU: { name: 'Hungary (Magyarország)', code: '36', alphaId: false },
    IS: { name: 'Iceland (Ísland)', code: '354', alphaId: true },
    IN: { name: 'India (भारत)', code: '91', alphaId: false },
    ID: { name: 'Indonesia', code: '62', alphaId: false },
    IR: { name: 'Iran (‫ایران‬‎)', code: '98', alphaId: false },
    IQ: { name: 'Iraq (‫العراق‬‎)', code: '964', alphaId: true },
    IE: { name: 'Ireland', code: '353', alphaId: true },
    IM: { name: 'Isle of Man', code: '44', alphaId: true },
    IL: { name: 'Israel (‫ישראל‬‎)', code: '972', alphaId: false },
    IT: { name: 'Italy (Italia)', code: '39', alphaId: true },
    JM: { name: 'Jamaica', code: '1', alphaId: true },
    JP: { name: 'Japan (日本)', code: '81', alphaId: true },
    JE: { name: 'Jersey', code: '44', alphaId: true },
    JO: { name: 'Jordan (‫الأردن‬‎)', code: '962', alphaId: false },
    KZ: { name: 'Kazakhstan (Казахстан)', code: '7', alphaId: false },
    KE: { name: 'Kenya', code: '254', alphaId: false },
    KI: { name: 'Kiribati', code: '686', alphaId: false },
    XK: { name: 'Kosovo', code: '383', alphaId: true },
    KW: { name: 'Kuwait (‫الكويت‬‎)', code: '965', alphaId: false },
    KG: { name: 'Kyrgyzstan (Кыргызстан)', code: '996', alphaId: false },
    LA: { name: 'Laos (ລາວ)', code: '856', alphaId: true },
    LV: { name: 'Latvia (Latvija)', code: '371', alphaId: true },
    LB: { name: 'Lebanon (‫لبنان‬‎)', code: '961', alphaId: true },
    LS: { name: 'Lesotho', code: '266', alphaId: true },
    LR: { name: 'Liberia', code: '231', alphaId: false },
    LY: { name: 'Libya (‫ليبيا‬‎)', code: '218', alphaId: true },
    LI: { name: 'Liechtenstein', code: '423', alphaId: true },
    LT: { name: 'Lithuania (Lietuva)', code: '370', alphaId: true },
    LU: { name: 'Luxembourg', code: '352', alphaId: true },
    MO: { name: 'Macau (澳門)', code: '853', alphaId: true },
    MG: { name: 'Madagascar (Madagasikara)', code: '261', alphaId: true },
    MW: { name: 'Malawi', code: '265', alphaId: true },
    MY: { name: 'Malaysia', code: '60', alphaId: false },
    MV: { name: 'Maldives', code: '960', alphaId: true },
    ML: { name: 'Mali', code: '223', alphaId: true },
    MT: { name: 'Malta', code: '356', alphaId: true },
    MH: { name: 'Marshall Islands', code: '692', alphaId: false },
    MQ: { name: 'Martinique', code: '596', alphaId: true },
    MR: { name: 'Mauritania (‫موريتانيا‬‎)', code: '222', alphaId: true },
    MU: { name: 'Mauritius (Moris)', code: '230', alphaId: true },
    YT: { name: 'Mayotte', code: '262', alphaId: true },
    MX: { name: 'Mexico (México)', code: '52', alphaId: false },
    FM: { name: 'Micronesia', code: '691', alphaId: false },
    MD: { name: 'Moldova (Republica Moldova)', code: '373', alphaId: true },
    MC: { name: 'Monaco', code: '377', alphaId: true },
    MN: { name: 'Mongolia (Монгол)', code: '976', alphaId: true },
    ME: { name: 'Montenegro (Crna Gora)', code: '382', alphaId: true },
    MS: { name: 'Montserrat', code: '1', alphaId: true },
    MA: { name: 'Morocco (‫المغرب‬‎)', code: '212', alphaId: false },
    MZ: { name: 'Mozambique (Moçambique)', code: '258', alphaId: true },
    MM: { name: 'Myanmar (Burma) (မြန်မာ)', code: '95', alphaId: false },
    NA: { name: 'Namibia (Namibië)', code: '264', alphaId: true },
    NR: { name: 'Nauru', code: '674', alphaId: false },
    NP: { name: 'Nepal (नेपाल)', code: '977', alphaId: false },
    NL: { name: 'Netherlands (Nederland)', code: '31', alphaId: true },
    NC: {
      name: 'New Caledonia (Nouvelle-Calédonie)',
      code: '687',
      alphaId: true
    },
    NZ: { name: 'New Zealand', code: '64', alphaId: false },
    NI: { name: 'Nicaragua', code: '505', alphaId: false },
    NE: { name: 'Niger (Nijar)', code: '227', alphaId: true },
    NG: { name: 'Nigeria', code: '234', alphaId: false },
    NU: { name: 'Niue', code: '683', alphaId: false },
    NF: { name: 'Norfolk Island', code: '672', alphaId: false },
    KP: { name: 'North Korea (조선 민주주의 인민 공화국)', code: '850', alphaId: false },
    MK: {
      name: 'North Macedonia (Северна Македонија)',
      code: '389',
      alphaId: true
    },
    MP: { name: 'Northern Mariana Islands', code: '1', alphaId: false },
    NO: { name: 'Norway (Norge)', code: '47', alphaId: true },
    OM: { name: 'Oman (‫عُمان‬‎)', code: '968', alphaId: false },
    PK: { name: 'Pakistan (‫پاکستان‬‎)', code: '92', alphaId: true },
    PW: { name: 'Palau', code: '680', alphaId: false },
    PS: { name: 'Palestine (‫فلسطين‬‎)', code: '970', alphaId: true },
    PA: { name: 'Panama (Panamá)', code: '507', alphaId: false },
    PG: { name: 'Papua New Guinea', code: '675', alphaId: true },
    PY: { name: 'Paraguay', code: '595', alphaId: false },
    PE: { name: 'Peru (Perú)', code: '51', alphaId: false },
    PH: { name: 'Philippines', code: '63', alphaId: false },
    PL: { name: 'Poland (Polska)', code: '48', alphaId: true },
    PT: { name: 'Portugal', code: '351', alphaId: true },
    PR: { name: 'Puerto Rico', code: '1', alphaId: false },
    QA: { name: 'Qatar (‫قطر‬‎)', code: '974', alphaId: false },
    RE: { name: 'Réunion (La Réunion)', code: '262', alphaId: true },
    RO: { name: 'Romania (România)', code: '40', alphaId: false },
    RU: { name: 'Russia (Россия)', code: '7', alphaId: false },
    RW: { name: 'Rwanda', code: '250', alphaId: false },
    BL: { name: 'Saint Barthélemy', code: '590', alphaId: false },
    SH: { name: 'Saint Helena', code: '290', alphaId: false },
    KN: { name: 'Saint Kitts and Nevis', code: '1', alphaId: false },
    LC: { name: 'Saint Lucia', code: '1', alphaId: false },
    MF: {
      name: 'Saint Martin (Saint-Martin (partie française))',
      code: '590',
      alphaId: false
    },
    PM: {
      name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
      code: '508',
      alphaId: false
    },
    VC: {
      name: 'Saint Vincent and the Grenadines',
      code: '1',
      alphaId: false
    },
    WS: { name: 'Samoa', code: '685', alphaId: true },
    SM: { name: 'San Marino', code: '378', alphaId: true },
    ST: {
      name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
      code: '239',
      alphaId: true
    },
    SA: {
      name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
      code: '966',
      alphaId: false
    },
    SN: { name: 'Senegal (Sénégal)', code: '221', alphaId: true },
    RS: { name: 'Serbia (Србија)', code: '381', alphaId: false },
    SC: { name: 'Seychelles', code: '248', alphaId: true },
    SL: { name: 'Sierra Leone', code: '232', alphaId: true },
    SG: { name: 'Singapore', code: '65', alphaId: false },
    SX: { name: 'Sint Maarten', code: '1', alphaId: false },
    SK: { name: 'Slovakia (Slovensko)', code: '421', alphaId: true },
    SI: { name: 'Slovenia (Slovenija)', code: '386', alphaId: true },
    SB: { name: 'Solomon Islands', code: '677', alphaId: true },
    SO: { name: 'Somalia (Soomaaliya)', code: '252', alphaId: true },
    ZA: { name: 'South Africa', code: '27', alphaId: false },
    KR: { name: 'South Korea (대한민국)', code: '82', alphaId: false },
    SS: {
      name: 'South Sudan (‫جنوب السودان‬‎)',
      code: '211',
      alphaId: false
    },
    ES: { name: 'Spain (España)', code: '34', alphaId: true },
    LK: { name: 'Sri Lanka (ශ්‍රී ලංකාව)', code: '94', alphaId: false },
    SD: { name: 'Sudan (‫السودان‬‎)', code: '249', alphaId: false },
    SR: { name: 'Suriname', code: '597', alphaId: true },
    SJ: { name: 'Svalbard and Jan Mayen', code: '47', alphaId: false },
    SE: { name: 'Sweden (Sverige)', code: '46', alphaId: true },
    CH: { name: 'Switzerland (Schweiz)', code: '41', alphaId: true },
    SY: { name: 'Syria (‫سوريا‬‎)', code: '963', alphaId: false },
    TW: { name: 'Taiwan (台灣)', code: '886', alphaId: false },
    TJ: { name: 'Tajikistan', code: '992', alphaId: true },
    TZ: { name: 'Tanzania', code: '255', alphaId: false },
    TH: { name: 'Thailand (ไทย)', code: '66', alphaId: false },
    TL: { name: 'Timor-Leste', code: '670', alphaId: true },
    TG: { name: 'Togo', code: '228', alphaId: true },
    TK: { name: 'Tokelau', code: '690', alphaId: false },
    TO: { name: 'Tonga', code: '676', alphaId: true },
    TT: { name: 'Trinidad and Tobago', code: '1', alphaId: true },
    TN: { name: 'Tunisia (‫تونس‬‎)', code: '216', alphaId: false },
    TR: { name: 'Turkey (Türkiye)', code: '90', alphaId: false },
    TM: { name: 'Turkmenistan', code: '993', alphaId: true },
    TC: { name: 'Turks and Caicos Islands', code: '1', alphaId: true },
    TV: { name: 'Tuvalu', code: '688', alphaId: false },
    VI: { name: 'U.S. Virgin Islands', code: '1', alphaId: false },
    UG: { name: 'Uganda', code: '256', alphaId: false },
    UA: { name: 'Ukraine (Україна)', code: '380', alphaId: true },
    AE: {
      name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
      code: '971',
      alphaId: false
    },
    GB: { name: 'United Kingdom', code: '44', alphaId: true },
    US: { name: 'United States', code: '1', alphaId: false },
    UY: { name: 'Uruguay', code: '598', alphaId: false },
    UZ: { name: 'Uzbekistan (Oʻzbekiston)', code: '998', alphaId: true },
    VU: { name: 'Vanuatu', code: '678', alphaId: true },
    VA: {
      name: 'Vatican City (Città del Vaticano)',
      code: '39',
      alphaId: false
    },
    VE: { name: 'Venezuela', code: '58', alphaId: false },
    VN: { name: 'Vietnam (Việt Nam)', code: '84', alphaId: false },
    WF: {
      name: 'Wallis and Futuna (Wallis-et-Futuna)',
      code: '681',
      alphaId: false
    },
    EH: {
      name: 'Western Sahara (‫الصحراء الغربية‬‎)',
      code: '212',
      alphaId: false
    },
    YE: { name: 'Yemen (‫اليمن‬‎)', code: '967', alphaId: true },
    ZM: { name: 'Zambia', code: '260', alphaId: false },
    ZW: { name: 'Zimbabwe', code: '263', alphaId: true },
    AX: { name: 'Åland Islands', code: '358', alphaId: false }
  }

  module.exports.list = list;
  