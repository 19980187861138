import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#003049',
          secondary: '#669BBC',
          accent: '#FDF0D5',
          error: '#C1121F',
          third: '#FDF0D5',
          fourth: '#780000'
        }
      },
    },
  });
