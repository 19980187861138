<template>
<div>
  <v-row>
  <v-col> 
    <v-card>
      <v-card-title> {{ $t('menu_sender') }} </v-card-title>
      <v-card-subtitle> {{ $t('sender_information') }} </v-card-subtitle>
    </v-card>
  </v-col>  
</v-row>
<v-row>
  <v-col>
    <v-card width="60%">
      <v-card-title>
       {{ this.$t('sender_alphaid') }}
      </v-card-title>
      <v-text-field
  ref="alphaidText"
  :rules="rules()"
  v-model="alphaid_input"
  counter
  maxlength="11"
  outlined
  class="mx-4"
></v-text-field>
<v-card-actions>
<v-btn @click="onSave">{{ $t('save')}}</v-btn>
</v-card-actions>
</v-card>
</v-col>
</v-row>
<v-row>
  <v-col> 
    <v-card width="60%">
      <v-card-title> {{ $t('check_recipient_country') }} </v-card-title>
      <v-card-subtitle> {{ $t('check_recipient_country_info') }}  </v-card-subtitle>
      <v-card>
    <v-card-text>
      <v-select
        v-model="selectedCountry"
        :items="country_items"
        item-text="text"
        item-value="text"
        :label="this.$t('select_country')"
        outlined
      ></v-select>
     <p v-if="checkIsAlphaIdAvailable()">
      {{ $t('check_recipient_country_true') }}
    </p>
    <p v-else-if="checkIsAlphaIdAvailable() === undefined"> 
    </p>
    <p v-else>
      {{ $t('check_recipient_country_false') }}
    </p>
    </v-card-text>
  </v-card>
    </v-card>
  </v-col>  
</v-row>

</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//import firebase from "firebase/compat";
import "firebase/compat/firestore";

const countries = require('@/assets/constants/countries.js')

export default {
  data() {
    return {
      alphaid_input : '',
      selectedCountry: null,
      country_items: Object.keys(countries.list).map(key => ({
        text: countries.list[key].name,
        value: countries.list[key].alphaId
      }))
    };
  },
  mounted() {
  },
  computed: {
    ...mapActions({
        loadAlphaId: 'accounts/loadAlphaId'
    }),
    ...mapGetters({
        alphaId: 'accounts/getAlphaId'
    }),

  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      updateAlphaId: 'accounts/updateAlphaId'
    }),
    checkIsAlphaIdAvailable() {
      var country_object = this.country_items.filter((e) => e.text == this.selectedCountry);
      return country_object.length > 0 ? country_object[0].value : undefined;
    },
    rules(){
      return [
          value => {
            const pattern = /^[A-Za-z1-9+\-_& ]*[A-Za-z][A-Za-z1-9+\-_& ]*$/;
            return pattern.test(value) || this.$t('invalid_alphaid')
          },
          value => value.length <= 11 || this.$t('max_11_chars'),
          value => value.length >= 3 || this.$t('min_3_chars'),
        ]
    },
    async initialize() {
        this.loading = true;
        try {
          await this.loadAlphaId;
          //await this.loadCategories;
        } catch (e) {
          console.error(e);
        }
        this.alphaid_input = this.alphaId;
        this.loading = false;
    },
    onSave (){
      if (this.$refs.alphaidText.validate()){
        this.updateAlphaId(this.alphaid_input);
      }
    }
  }
};
</script>