import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import accounts from './modules/accounts';
import categories from './modules/categories';
import sms from './modules/sms';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    categories,
    accounts,
    sms
  }
})
