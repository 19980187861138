module.exports = {
    save_your_time: 'Zamanınızı Tasarruf Edin',
    welcome: 'Hoş Geldiniz',
    hello: 'Merhaba',
    no_show_reduction: 'Görüşme İptal Etme Oranını Azaltın',
    manage_reminders: 'Kolay ve sezgisel bir panelle hatırlatmaları yönetin',
    do_you_know_that: 'Biliyor musunuz?',
    app_description_short: 'Google Takvim\'den randevu SMS hatırlatmaları göndermek için bir uygulama',
    more_about_product: 'Ürün Hakkında Daha Fazla Bilgi',
    personal_assistant: 'Kişisel Zaman Asistanı',
    personal_assistant_text: 'Notifyapp - Zaman yönetimi ve SMS ile randevu hatırlatmaları gönderme konusunda modern bir uygulama. Her zaman zamanında olun!',
    forget_about_lateness: 'Gecikmeleri Unutun',
    forget_about_lateness_text: 'Notifyapp sayesinde gereksiz gecikmeleri unutun ve her zaman görüşmeye hazır olun. Otomatik SMS hatırlatmaları sayesinde zamanında yetişebilirsiniz.',
    less_no_shows: 'Daha Az Görüşme İptali',
    less_no_shows_text: 'Notifyapp, görüşmelerin iptal edilme oranını azaltmada yardımcı olur. SMS hatırlatmalarıyla hızlı bir şekilde tepki verebilir ve gerektiğinde görüşmeyi iptal edebilirsiniz.',
    simple_use: 'Kolay ve sezgisel kullanım',
    simple_use_text: 'Notifyapp - herkes için kullanımı kolay bir uygulama. Google hesabınızla bağlantı kurun ve randevuları eklemeye başlayın. Otomatik SMS hatırlatmaları arka planda çalışır.',
    brand_building: 'Marka Oluşturma ve Müşteri Geri Bildirimleri',
    brand_building_text: 'Notifyapp, marka oluşturmanıza ve müşteri geri bildirimleri toplamanıza yardımcı olan ek özellikler sunar. Görüşme sonrasında geri bildirim istekleri gönderilir, bu da işinizi geliştirmenize yardımcı olur.',
    get_addon: 'Eklentiyi Alın',
    get_addon_text: 'Lütfen bir eklenti seçin ve otomatik olarak Google Marketplace\'e yönlendirileceksiniz, burada etkinleştirebilirsiniz. Yüklendikten sonra, uzantılarımız tüm tarayıcılardaki takviminize otomatik olarak eklenir ve aynı SMS paketini kullanır.',
    get_addon_notify: '"Gelmeyenleri azaltın!" Google takviminizden doğrudan müşterilerinize otomatik SMS randevu hatırlatıcıları gönderin!',
    get_addon_opinion: 'Google\'da daha fazla değerlendirme alın! Google Takvim™ üzerinden doğrudan müşterilerinize otomatik SMS sonuç bildirim hatırlatmaları gönderin!',
    navigation_home: 'Ana Sayfa',
    navigation_product: 'Ürün',
    navigation_download: 'İndir',
    navigation_pricing: 'Fiyatlandırma',
    navigation_contact: 'İletişim',
    navigation_login: 'Giriş',
    monthly_yearly: 'Aylık / Yıllık',
    save_choosing_yearly_plan: 'Yıllık planı seçerek %20 tasarruf edin',
    subscribe: 'Abone Ol',
    loading: 'Yükleniyor...',
    need_more: 'Daha Fazlasına Mı İhtiyacınız Var?',
    contact_with_us: 'Bizimle İletişime Geçin!',
    sms_per_month: 'SMS/ay',
    no_calendar_limit: 'Sınırsız takvim',
    no_user_limit: 'Sınırsız kullanıcı',
    contact: 'İletişim',
    contact_text: 'Sorularınız mı var? Bizimle iletişime geçin.',
    we_answer_your_questions: 'Sorularınıza ve endişelerinize cevap veriyoruz',
    name: 'Ad',
    email: 'E-posta',
    message: 'Mesaj',
    send: 'Gönder',
    close: 'Kapat',
    name_is_required: 'Ad zorunludur',
    email_is_required: 'E-posta adresi zorunludur',
    email_is_invalid: 'Geçersiz e-posta adresi',
    message_is_required: 'Mesaj içeriği zorunludur',
    message_sent: 'Mesajınız için teşekkür ederiz',
    message_not_sent: 'Mesaj gönderilirken hata oluştu',
    terms: 'Koşullar',
    privacy_policy: 'Gizlilik Politikası',
    users: 'Kullanıcılar',
    loading_please_wait: 'Yükleniyor, lütfen bekleyin...',
    add_user: 'Kullanıcı Ekle',
    user_email: 'Kullanıcı E-postası',
    cancel: 'İptal',
    save: 'Kaydet',
    new_user: 'Yeni Kullanıcı',
    edit_user: 'Kullanıcıyı Düzenle',
    account_has_been_deleted: 'Hesap silindi',
    account_could_not_be_deleted: 'Hesap silinemedi',
    delete_item_are_you_sure: 'Bu öğeyi silmek istediğinize emin misiniz?',
    user_has_been_updated: 'Kullanıcı güncellendi',
    user_has_not_been_updated: 'Kullanıcı güncellenmedi',
    user_has_been_added: 'Kullanıcı eklendi',
    user_has_not_been_added: 'Kullanıcı eklenmedi',
    menu_dashboard: 'Pano',
    menu_users: 'Kullanıcılar',
    menu_plans: 'Planlar',
    menu_settings: 'Ayarlar',
    menu_addons: 'Eklentiler',
    menu_support: 'Destek',
    loading_account_information: 'Hesap bilgileri yükleniyor...',
    max_11_chars: 'En fazla 11 karakter',
    min_3_chars: 'En az 3 karakter',
    invalid_alphaid: 'Geçersiz Alfa Kimliği. En az bir harf içermelidir. Küçük ve büyük harfler, rakamlar, -, _, +, & ve boşluk karakterlerine izin verilir.',
    settings: 'Ayarlar',
    subscribed_account: 'Aboneliğe sahip hesap',
    subscription_cancel_at_period_end: 'Abonelik, geçerli dönem sonunda iptal edilecek',
    current_period_start: 'Geçerli dönem başlangıcı',
    current_period_end: 'Geçerli dönem sonu',
    manage_subscription: 'Aboneliği Yönet',
    subscription_plans: 'Abonelik Planları',
    welcome_dialog_title: "Hoş geldiniz bonusu: 10 SMS",
    welcome_dialog_subtitle: "Kaydınız için teşekkür ederiz. Hesabınıza 10 SMS ekledik. Servisimizi test etmek için bunları kullanabilirsiniz.",
    welcome_dialog_text: "Google Calendar™ eklentimizi denemek için kurun. Aşağıda Google Workspace Marketplace'e bir bağlantı bulacaksınız. Herhangi bir sorunuz varsa, lütfen bize şu adresten ulaşın: ",
    back_to_home_page: "Ana Sayfaya Dön",
    feel_free_to_ask: "Herhangi bir sorunuz varsa, sormaktan çekinmeyin. Bize ulaşmak için aşağıdaki formu kullanabilir veya support@notifyapp.cloud adresine e-posta gönderebilirsiniz.",
    please_email_us: "Lütfen bize e-posta gönderin:",
    users_information: "Burada ek kullanıcıları eklemek için seçeneğiniz var. Bu kişiler otomatik olarak lisanslarınıza ve paketlerinize bağlanacak. Onlar da sizin gibi uygulamaya aynı erişime sahip olacaklar, ancak unutmayın, siz yönetici olarak kalacaksınız. Kimseyi eklemezseniz, yalnızca siz müşterilerinize metin mesajları gönderebileceksiniz.",
    menu_sender: 'Gönderen',
    sender_information: 'Bu ayarlar, SMS mesajlarınızın göndereni olarak görüntülenecek adı telefon numarasının yerine özelleştirmenize olanak sağlar. Ancak, bazı ülkelerde yasal düzenlemeler nedeniyle bu seçenek sınırlı olabilir. İzin verilen durumlarda, markanıza uyumlu belirgin bir gönderen adı oluşturun. Tanınabilir bir gönderen aracılığıyla mesajınızı iletmek için esnekliğin keyfini çıkarın! Kişiselleştirilmiş bir dokunuşla bağlantınızı sürdürün.',
    sender_alphaid: 'SMS Gönderen Adı',
    check_recipient_country: 'Alıcı ülkeyi kontrol et',
    check_recipient_country_info: 'Alıcı ülkeyi seçin ve özel gönderici adının kullanılabilir olup olmadığını kontrol edin.',
    check_recipient_country_true: 'Bu ülkede özel gönderici adları kullanılabilir.',
    check_recipient_country_false: 'Bu ülkede özel gönderici adları kullanılamaz. Mesaj göndericisi aşağıdaki numara olacaktır: +1 3608032010. Özel bir numaraya ilgi duyuyorsanız, lütfen bizimle iletişime geçin.',
    select_country: 'Ülke seçin',
    choose: "Seçiyorum",
    plans_info_no_test_sms: "Paketi satın almadan uygulamayı test edin! Yeni kullanıcılar için 10 ücretsiz metin mesajı alın. Yeni kaydolduysanız, Google Calendar™ eklentisini güncellemeyi unutmayın.",
    dashboard_info: 'NotifyApp Gösterge Tablonuza hoş geldiniz! Burada, gönderilen mesajların sayısı, planlanan mesajlar ve takım üyeleriniz dahil olmak üzere tüm önemli bilgilerinizi tek bir bakışta görüntüleyebilir ve yönetebilirsiniz. Bilgilendirilmeyi sürdürün ve iletişim metriklerinizi takip edin.',
    dashboard_messages_amount: 'Mesajlarınızın miktarı',
    dashboard_teammates: 'Takım arkadaşlarınız',
    dashboard_scheduled_messages: 'Planlanan mesajlar',
    dashboard_your_plan: 'Planınız',
    dashboard_free_sms: 'Ücretsiz 10 SMS',
    dashboard_upgrade: 'Daha fazlası için ücretli bir plana yükseltin.',
    dashboard_last_messages: 'Son 10 mesaj',
    dashboard_next_messages: 'Sonraki 10 mesaj',
    dashboard_date: 'Tarih',
    dashboard_recipient: 'Alıcı',
    dashboard_next_invoice: 'Sonraki fatura: ',
    dashboard_no_messages: 'Mesaj yok',
  };
  