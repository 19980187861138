module.exports = {
    save_your_time: 'Oszczędź swój czas',
    welcome: 'Witaj',
    hello: 'Cześć',
    no_show_reduction: 'Zredukować ilość nieodbytych spotkań',
    manage_reminders: 'Zarządzać przypomnieniami jak profesjonalista z prostego i intuicyjnego panelu',
    do_you_know_that: 'Czy wiesz, że?',
    app_description_short: 'Aplikacja do wysyłania przypomnień SMS o spotkaniach z kalendarza Google',
    more_about_product: 'Więcej o produkcie',
    personal_assistant: "Osobisty asystent czasu",
    personal_assistant_text: "Notifyapp - nowoczesna aplikacja do zarządzania czasem i wysyłania przypomnień o spotkaniach za pomocą SMS-ów. Bądź zawsze na czas!",
    forget_about_lateness: "Zapomnij o opóźnieniach",
    forget_about_lateness_text: "Dzięki Notifyapp unikniesz niepotrzebnych spóźnień i zawsze będziesz przygotowany na spotkanie. Automatyczne przypomnienia SMS pomogą Ci być punktualnym.",
    less_no_shows: "Mniej nieodbytych spotkań",
    less_no_shows_text: "Notifyapp pomaga w redukcji ilości spotkań bez udziału. Dzięki przypomnieniom SMS będziesz mógł szybko zareagować i odwołać spotkanie, jeśli zajdzie taka potrzeba.",
    simple_use: "Prosta i intuicyjna obsługa",
    simple_use_text: "Notifyapp - łatwa w obsłudze aplikacja dostępna dla każdego. Połącz z kontem Google i zacznij dodawać spotkania. Automatyczne przypomnienia SMS działają w tle.",
    brand_building: "Budowanie marki i opinie klientów",
    brand_building_text: "Notifyapp oferuje dodatkowe funkcje, które pomagają w budowaniu marki i zbieraniu opinii od klientów. Po spotkaniu wysyłane są prośby o opinię, co pomaga w rozwijaniu firmy.",
    get_addon: "Pobierz dodatek",
    get_addon_text: 'Proszę wybrać odpowiednie rozszerzenie, które przeniesie Cię automatycznie do Sklepu Google, gdzie będzie można je aktywować. Po zainstalowaniu, nasze rozszerzenia zostaną automatycznie dodane do Twojego kalendarza we wszystkich przeglądarkach, korzystając z tego samego pakietu SMS.',
    get_addon_notify: '"Zmniejsz liczbę nieobecności!" Wysyłaj automatyczne przypomnienia SMS o umówionych spotkaniach bezpośrednio z Twojego kalendarza Google!',
    get_addon_opinion: 'Zdobądź więcej opinii na temat firmy na Google! Wysyłaj automatyczne przypomnienia SMS o daniu opinii po spotkaniu bezpośrednio z kalendarza Google™.',
    navigation_home: "Home",
    navigation_product: "Produkt",
    navigation_download: "Pobierz",
    navigation_pricing: "Cennik",
    navigation_contact: "Kontakt",
    navigation_login: "Zaloguj",
    monthly_yearly: "Miesięcznie / Rocznie",
    save_choosing_yearly_plan: "Oszczędzaj 20% wybierając plan roczny",
    subscribe: "Subskrybuję",
    loading: "Ładowanie...",
    need_more: "Potrzebujesz więcej?",
    contact_with_us: "Skontaktuj się z nami!",    
    sms_per_month: 'SMS/miesiąc',
    no_calendar_limit: 'bez limitu kalendarzy',
    no_user_limit: 'bez limitu uzytkowników',
    contact: 'Kontakt',
    contact_text: 'Masz pytania? Skontaktuj się z nami.',
    we_answer_your_questions: 'Odpowiemy na wszystkie Twoje pytania i wątpliwości',
    name: 'Imię',
    email: 'Email',
    message: 'Wiadomość',
    send: 'Wyślij',
    close: 'Zamknij',
    name_is_required: 'Imię jest wymagane',
    email_is_required: 'Adres email jest wymagany',
    email_is_invalid: 'Adres email jest niepoprawny',
    message_is_required: 'Treść wiadomości jest wymagana',
    message_sent: 'Dziękujemy za wiadomość',
    message_not_sent: "Błąd podczas wysyłania wiadomości",
    terms: 'Regulamin',
    privacy_policy: 'Polityka prywatności',
    users: 'Użytkownicy',
    loading_please_wait: 'Ładowanie, proszę czekać...',
    add_user: 'Dodaj użytkownika',
    user_email: 'Email użytkownika',
    cancel: 'Anuluj',
    save: 'Zapisz',
    new_user: 'Nowy użytkownik',
    edit_user: 'Edycja użytkownika',
    account_has_been_deleted :'Account has been deleted',
    account_could_not_be_deleted :'Account could not be deleted',
    delete_item_are_you_sure: "Czy na pewno chcesz usunąć ten element?",
    user_has_been_updated: 'Użytkownik został zaktualizowany',
    user_has_not_been_updated: 'Użytkownik nie został zaktualizowany',
    user_has_been_added: 'Użytkownik został dodany',
    user_has_not_been_added: 'Użytkownik nie został dodany',
    menu_dashboard: 'Panel',
    menu_users: 'Użytkownicy',
    menu_plans: 'Plany',
    menu_settings: 'Ustawienia',
    menu_addons: 'Dodatki',
    menu_support: 'Pomoc',
    loading_account_information: 'Ładowanie informacji o koncie...',
    max_11_chars: 'Maksymalnie 11 znaków',
    min_3_chars: 'Minimum 3 znaki',
    invalid_alphaid: 'Nieprawidłowy alphaid. Musi zawierać przynajmiej jedną literę. Dozwolone znaki to: małe i duze litery, cyfry, -, _, +, & oraz spacja.',
    settings: 'Ustawienia',
    subscribed_account: 'Konto subskrypcyjne',
    subscription_cancel_at_period_end: 'Subskrypcja zostanie anulowana na koniec okresu rozliczeniowego',
    current_period_start: 'Obecny okres rozliczeniowy rozpoczął się',
    current_period_end: 'Obecny okres rozliczeniowy zakończy się',
    manage_subscription: 'Zarządzaj subskrypcją',
    subscription_plans: 'Plany subskrypcyjne',
    welcome_dialog_title: "Bonus powitalny: 10 SMS-ów",
    welcome_dialog_subtitle: "Dziękujemy za rejestrację. Dodaliśmy 10 SMS-ów do twojego konta. Możesz ich użyć, aby przetestować naszą usługę.",
    welcome_dialog_text: "Zainstaluj nasze rozszerzenie Google Calendar™, aby je wypróbować. Poniżej znajdziesz link do sklepu Google Workspace Marketplace. Jeśli masz jakiekolwiek pytania, skontaktuj się z nami pod adresem ",
    back_to_home_page: "Powrót do strony głównej",
    feel_free_to_ask: "Jeśli masz jakiekolwiek pytania, śmiało je zadać. Skorzystaj z poniższego formularza, aby skontaktować się z nami lub napisz e-mail na support@notifyapp.cloud.",
    please_email_us: "Prosimy o wysłanie wiadomości e-mail:",
    users_information: "Tutaj masz możliwość dodania dodatkowych użytkowników do swojego zespołu. Osoby te zostaną automatycznie połączone z twoimi licencjami i pakietami. Będą miały takie same uprawnienia do aplikacji jak ty, jednak pamiętaj, że pozostaniesz administratorem. Jeśli nie dodasz nikogo, tylko ty będziesz mógł wysyłać wiadomości tekstowe do swoich klientów.",
    menu_sender: 'Nadawca',
    sender_information: 'Ta opcja pozwala dostosować nazwę, która będzie wyświetlana jako nadawca Twoich wiadomości SMS, zamiast numeru telefonu. Należy jednak pamiętać, że w niektórych krajach opcja ta może być ograniczona ze względu na przepisy prawne. Jeśli jest to dozwolone, stwórz rozpoznawalną nazwę nadawcy, która będzie zgodna z Twoją marką. Ciesz się elastycznością przekazywania swojej wiadomości poprzez rozpoznawalnego nadawcę! Pozostań w kontakcie z personalizowanym dotykiem.',
    sender_alphaid: 'Nazwa nadawcy SMS',
    check_recipient_country: 'Sprawdź kraj odbiorcy',
    check_recipient_country_info: 'Wybierz kraj odbiorcy i sprawdź, czy dostępna jest niestandardowa nazwa nadawcy.',
    check_recipient_country_true: 'Ten kraj umożliwia niestandardowe nazwy nadawców.',
    check_recipient_country_false: 'Ten kraj nie umożliwia niestandardowych nazw nadawców. Nadawcą wiadomości będzie następujący numer: +1 3608032010. Jeśli jesteś zainteresowany dedykowanym numerem, skontaktuj się z nami.',
    select_country: 'Wybierz kraj',
    choose: "Wybieram",
    plans_info_no_test_sms: "Testuj aplikację bez konieczności zakupu pakietu! Nowi użytkownicy otrzymują 10 darmowych wiadomości tekstowych do testowania. Jeśli właśnie się zarejestrowałeś, nie zapomnij odświeżyć dodatku Google Calendar™.",
    dashboard_info: 'Witaj w swoim panelu NotifyApp! Tutaj możesz przeglądać i zarządzać wszystkimi ważnymi informacjami na pierwszy rzut oka. Obejmuje to liczbę wysłanych wiadomości, zaplanowane wiadomości i członków twojego zespołu. Bądź na bieżąco i śledź swoje statystyki komunikacyjne.',
    dashboard_messages_amount: 'Twoja ilość wiadomości',
    dashboard_teammates: 'Twoi członkowie zespołu',
    dashboard_scheduled_messages: 'Zaplanowane wiadomości',
    dashboard_your_plan: 'Twój plan',
    dashboard_free_sms: 'Darmowe 10 SMS',
    dashboard_upgrade: 'Przejdź na płatny plan, aby uzyskać więcej.',
    dashboard_last_messages: 'Ostatnie 10 wiadomości',
    dashboard_next_messages: 'Następne 10 wiadomości',
    dashboard_date: 'Data',
    dashboard_recipient: 'Odbiorca',
    dashboard_next_invoice: 'Następna faktura: ',
    dashboard_no_messages: 'Brak wiadomości',
  };
