module.exports = {
    save_your_time: 'Economize seu tempo',
    welcome: 'Bem-vindo',
    hello: 'Olá',
    no_show_reduction: 'Reduza o número de faltas',
    manage_reminders: 'Gerencie lembretes como um profissional, com um painel simples e intuitivo',
    do_you_know_that: 'Você sabia?',
    app_description_short: 'Um aplicativo para enviar lembretes SMS de compromissos do seu calendário do Google',
    more_about_product: 'Saiba mais sobre o produto',
    personal_assistant: 'Assistente pessoal de tempo',
    personal_assistant_text: 'Notifyapp - um aplicativo moderno para gerenciar o tempo e enviar lembretes de compromissos por SMS. Esteja sempre no horário!',
    forget_about_lateness: 'Esqueça o atraso',
    forget_about_lateness_text: 'Com o Notifyapp, você evitará atrasos desnecessários e estará sempre preparado para suas reuniões. Lembretes automáticos por SMS ajudarão você a ser pontual.',
    less_no_shows: 'Menos faltas',
    less_no_shows_text: 'O Notifyapp ajuda a reduzir o número de faltas em compromissos. Com lembretes por SMS, você poderá reagendar ou cancelar rapidamente uma reunião, se necessário.',
    simple_use: 'Uso simples e intuitivo',
    simple_use_text: 'Notifyapp - um aplicativo de fácil utilização disponível para todos. Conecte-se à sua conta do Google e comece a adicionar compromissos. Lembretes automáticos por SMS funcionam em segundo plano.',
    brand_building: 'Construção de marca e avaliações dos clientes',
    brand_building_text: 'Notifyapp oferece recursos adicionais para ajudar na construção da marca e coletar avaliações dos clientes. Após uma reunião, solicitações de avaliação são enviadas, o que auxilia no desenvolvimento do seu negócio.',
    get_addon: 'Obter complemento',
    get_addon_text: 'Por favor, selecione uma extensão e você será automaticamente redirecionado para a Google Marketplace, onde poderá ativá-la. Uma vez instaladas, nossas extensões serão automaticamente adicionadas ao seu calendário em cada navegador, usando o mesmo pacote de SMS.',
    get_addon_notify: '"Reduza as faltas!" Envie lembretes automáticos de compromissos por SMS para seus clientes diretamente do seu calendário do Google!',
    get_addon_opinion: 'Obtenha mais avaliações no Google! Envie lembretes automáticos de feedback pós-reunião via SMS para seus clientes diretamente do Google Calendar™.',
    navigation_home: 'Início',
    navigation_product: 'Produto',
    navigation_download: 'Download',
    navigation_pricing: 'Preços',
    navigation_contact: 'Contato',
    navigation_login: 'Login',
    monthly_yearly: 'Mensal / Anual',
    save_choosing_yearly_plan: 'Economize 20% ao escolher o plano anual',
    subscribe: 'Assinar',
    loading: 'Carregando...',
    need_more: 'Precisa de mais?',
    contact_with_us: 'Entre em contato conosco!',
    sms_per_month: 'SMS por mês',
    no_calendar_limit: 'Sem limite de calendários',
    no_user_limit: 'Sem limite de usuários',
    contact: 'Contato',
    contact_text: 'Tem alguma pergunta? Entre em contato conosco.',
    we_answer_your_questions: 'Responderemos a todas as suas perguntas e dúvidas',
    name: 'Nome',
    email: 'E-mail',
    message: 'Mensagem',
    send: 'Enviar',
    close: 'Fechar',
    name_is_required: 'O nome é obrigatório',
    email_is_required: 'O e-mail é obrigatório',
    email_is_invalid: 'O e-mail é inválido',
    message_is_required: 'A mensagem é obrigatória',
    message_sent: 'Obrigado pela mensagem',
    message_not_sent: 'Erro ao enviar a mensagem',
    terms: 'Termos',
    privacy_policy: 'Política de Privacidade',
    users: 'Usuários',
    loading_please_wait: 'Carregando, por favor aguarde...',
    add_user: 'Adicionar usuário',
    user_email: 'E-mail do usuário',
    cancel: 'Cancelar',
    save: 'Salvar',
    new_user: 'Novo usuário',
    edit_user: 'Editar usuário',
    account_has_been_deleted :'A conta foi excluída',
    account_could_not_be_deleted :'A conta não pôde ser excluída',
    delete_item_are_you_sure: 'Tem certeza de que deseja excluir este item?',
    user_has_been_updated: 'O usuário foi atualizado',
    user_has_not_been_updated: 'O usuário não foi atualizado',
    user_has_been_added: 'O usuário foi adicionado',
    user_has_not_been_added: 'O usuário não foi adicionado',
    menu_dashboard: 'Painel',
    menu_users: 'Usuários',
    menu_plans: 'Planos',
    menu_settings: 'Configurações',
    menu_addons: 'Complementos',
    menu_support: 'Suporte',
    loading_account_information: 'Carregando informações da conta...',
    max_11_chars: 'Máximo de 11 caracteres',
    min_3_chars: 'Mínimo 3 caracteres',
    invalid_alphaid: 'AlfaID inválido. Deve conter pelo menos uma letra. Caracteres permitidos: letras maiúsculas e minúsculas, números, -, _, +, & e espaço.',
    settings: 'Configurações',
    subscribed_account: 'Conta subscrita',
    subscription_cancel_at_period_end: 'A assinatura será cancelada no final do período de faturamento',
    current_period_start: 'O período de faturamento atual começou em',
    current_period_end: 'O período de faturamento atual termina em',
    manage_subscription: 'Gerenciar assinatura',
    subscription_plans: 'Planos de assinatura',
    welcome_dialog_title: "Bônus de boas-vindas: 10 SMS",
    welcome_dialog_subtitle: "Obrigado por se registrar. Adicionamos 10 SMS à sua conta. Você pode usá-los para testar nosso serviço.",
    welcome_dialog_text: "Instale nosso complemento Google Calendar™ para experimentá-lo. Abaixo você encontrará um link para o Google Workspace Marketplace. Se tiver alguma dúvida, entre em contato conosco pelo email: ",
    back_to_home_page: "Voltar à página inicial",
    feel_free_to_ask: "Se você tiver alguma dúvida, fique à vontade para perguntar. Utilize o formulário abaixo para entrar em contato conosco ou escreva um e-mail para support@notifyapp.cloud.",
    please_email_us: "Por favor, envie-nos um e-mail:",
    users_information: "Aqui você tem a opção de adicionar usuários extras à sua equipe. Essas pessoas serão automaticamente conectadas às suas licenças e pacotes. Elas terão o mesmo acesso à aplicação que você, mas lembre-se de que você permanecerá como o administrador. Se você não adicionar ninguém, somente você poderá enviar mensagens de texto para seus clientes.",
    menu_sender: 'Remetente',
    sender_information: 'Essas configurações permitem que você personalize o nome exibido como remetente de suas mensagens SMS, substituindo o número de telefone. No entanto, observe que essa opção pode ser restrita em alguns países devido a regulamentações legais. Se permitido, crie um nome distintivo de remetente que esteja alinhado com sua marca. Aproveite a flexibilidade de transmitir sua mensagem por meio de um remetente reconhecível! Mantenha-se conectado com um toque personalizado.',
    sender_alphaid: 'Nome do Remetente SMS',
    check_recipient_country: 'Verificar país do destinatário',
    check_recipient_country_info: 'Selecione o país do destinatário e verifique se é possível usar um nome de remetente personalizado.',
    check_recipient_country_true: 'Este país permite nomes de remetente personalizados.',
    check_recipient_country_false: 'Este país não permite nomes de remetente personalizados. O remetente da mensagem será o seguinte número: +1 3608032010. Se você estiver interessado em um número dedicado, entre em contato conosco.',
    select_country: 'Selecionar país',
    choose: "Escolher",
    plans_info_no_test_sms: "Teste o aplicativo sem comprar o pacote! Novos usuários recebem 10 mensagens de texto gratuitas para teste. Se você acabou de se registrar, lembre-se de atualizar o complemento do Google Calendar™.",
    dashboard_info: 'Bem-vindo ao seu Painel NotifyApp! Aqui, você pode visualizar e gerenciar todas as suas informações importantes de uma só vez. Isso inclui o número de mensagens enviadas, mensagens agendadas e os membros da sua equipe. Mantenha-se informado e acompanhe suas métricas de comunicação.',
    dashboard_messages_amount: 'A quantidade de suas mensagens',
    dashboard_teammates: 'Seus companheiros de equipe',
    dashboard_scheduled_messages: 'Mensagens agendadas',
    dashboard_your_plan: 'Seu plano',
    dashboard_free_sms: '10 SMS grátis',
    dashboard_upgrade: 'Faça upgrade para um plano pago para mais.',
    dashboard_last_messages: 'Últimas 10 mensagens',
    dashboard_next_messages: 'Próximas 10 mensagens',
    dashboard_date: 'Data',
    dashboard_recipient: 'Destinatário',
    dashboard_next_invoice: 'Próxima fatura: ',
    dashboard_no_messages: 'Sem mensagens',
  };
  