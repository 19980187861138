<template>
<div>
<v-row>
  <v-col> 
    <v-card>
      <v-card-title>{{ $t('menu_addons') }}</v-card-title>
      <v-card-subtitle>{{ $t('get_addon_text') }}</v-card-subtitle>
    </v-card>
  </v-col>
</v-row>
<v-row>
  <v-col>
<v-card
         width="60%" 
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                >Notifyapp</v-card-title>

                <v-card-subtitle>{{ $t('get_addon_notify') }}</v-card-subtitle>

                <v-card-actions>
                  <a href="https://workspace.google.com/marketplace/app/notifyapp/256932740718?pann=b" target="_blank" aria-label="Get it from the Google Workspace Marketplace">
  <img alt="Google Workspace Marketplace badge" alt-text="Get it from the Google Workspace Marketplace" src="https://workspace.google.com/static/img/marketplace/en/gwmBadge.svg?" style="height: 68px">
</a>
                </v-card-actions>
              </div>

  
              <v-img
                src='@/assets/img/notify_thumb.png'
                max-height="200"
                max-width="200"
                :style="{ margin: '10px' }"
                contain
              >
                </v-img>
              
            </div>
          </v-card>
                  </v-col>
</v-row> 
<v-row class="mt-3">
  <v-col>
          <v-card
         width="60%"   
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h5"
                >SMS reviews reminder </v-card-title>

                <v-card-subtitle>{{ $t('get_addon_opinion') }}</v-card-subtitle>

                <v-card-actions>


                  <a href="https://workspace.google.com/marketplace/app/reviews_reminder_for_google_calendar_by/835488806944?pann=b" target="_blank" aria-label="Get it from the Google Workspace Marketplace">
  <img alt="Google Workspace Marketplace badge" alt-text="Get it from the Google Workspace Marketplace" src="https://workspace.google.com/static/img/marketplace/en/gwmBadge.svg?" style="height: 68px">
</a>
                </v-card-actions>
              </div>


                <v-img
                src='@/assets/img/opinion_thumb.png'
                max-height="200"
                max-width="200"
                :style="{ margin: '10px' }"
                contain 
                ></v-img>

            </div>
          </v-card>
        </v-col>
        </v-row>
</div>
</template>

<script>
//import firebase from "firebase/compat";
import "firebase/compat/firestore";

export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  methods: {
  },
};
</script>