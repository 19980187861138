<template>
  <div>
    <v-row>
  <v-col> 
    <v-card style="margin-bottom: 10px;">
  <v-card-title> {{ $t('menu_users') }} </v-card-title>
  <v-card-text> {{ $t('users_information') }}</v-card-text>
</v-card>
</v-col>
</v-row>
    <v-data-table :headers="headers" :items="accounts"  :search="search" class="elevation-1" 
  :loading="loading" :loading-text="this.$t('loading_please_wait')">
    <template v-slot:top>

      <v-toolbar flat>
        <!--
        <v-toolbar-title>{{ $t('users')}}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical /> 
        <v-spacer />
        -->
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" >{{ $t('add_user') }}</v-btn>
          </template>
          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form v-bind:disabled="loading" lazy-validation ref="dialogForm">
                  <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field :rules="emailRule()" :disabled="loading" v-model="editedItem.email" :label="$t('user_email')"></v-text-field>
                  </v-col>
                </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="loading" color="blue darken-1" text @click="close">{{ $t('cancel') }}</v-btn>
              <v-btn :disabled="loading" color="blue darken-1" text @click="save">{{ $t('save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn text icon class="mb-2 ml-2" @click="initialize">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        
      </v-toolbar>
    </template>

    <template v-slot:[`body.prepend`]="{ headers }">
        <tr class="mx-0 px-0">
          <td :colspan="headers.length" class="mx-0 px-0">
            <v-text-field  v-model="search" prepend-inner-icon="mdi-magnify" label="Search" single-line hide-details filled class="px-0 mx-0"/>
          </td>
        </tr>
    </template>

    <template  v-slot:[`item.actions`]="{ item }" >
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        color="error">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>

  <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">{{ $t('close') }}</v-btn>
      </template>
  </v-snackbar>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      search: '',
      loading: true,
      dialog: false,
      headers: [
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: "email",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        email: ""
      },
      defaultItem: {
        email: ""
      },
    };
  },
  computed: {
    ...mapActions({
        loadAccounts: 'accounts/loadAccounts',
        loadCategories: 'categories/loadCategories',
    }),
    ...mapGetters({
        accounts: 'accounts/getAccounts',
        categories: 'categories/getCategories'
    }),
    formTitle() {
      return this.editedIndex === -1 ? this.$t('new_user') : this.$t('edit_user');
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();

  },
  methods: {
    ...mapActions({
      addAccount: 'accounts/addAccount',
      updateAccount: 'accounts/updateAccount',
      removeAccount: 'accounts/removeAccount'
    }),
    getCategoryById: function(id) {
      let category = this.categories.filter(c => c.id == id)[0];
      if(category === undefined) {
        console.log('undefined qarşim');
        return {title: 'Unknown'};
      }
      return category;
    },
    async initialize() {
        this.loading = true;
        try {
          await this.loadAccounts;
          //await this.loadCategories;
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.accounts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    emailRule(){ 
    return [
      v => !!v || this.$t('email_is_required'),
      v => /.+@.+\..+/.test(v) || this.$t('email_is_invalid'),
    ]
    },
    async deleteItem(item) {
      this.loading = true;
      if (confirm(this.$t('delete_item_are_you_sure'))) {
        this.loading = true;
        try {
          await this.removeAccount(item);
          this.loading = false;

          this.snack = true;
          this.snackColor = 'success';
          this.snackText = this.$t('account_has_been_deleted');

        } catch (e) {
          this.loading = false;

          this.snack = true
          this.snackColor = 'error'
          this.snackText = this.$t('account_has_not_been_deleted')

          console.error(e);
        }
      } else {
        this.loading = false;
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      
      if(!this.$refs.dialogForm.validate()) {
        return
      }

      
      if (this.editedIndex > -1) {
        this.loading = true;
        try {
          await this.updateAccount({index: this.editedIndex, account: this.editedItem});
          this.loading = false;
          this.close();

          this.snack = true
          this.snackColor = 'success'
          this.snackText = this.$t('user_has_been_updated')

        } catch (e) {
          this.loading = false;
          this.close();

          this.snack = true
          this.snackColor = 'error'
          this.snackText = this.$t('user_has_not_been_updated')

          console.error(e);  
        }
      } 
      else {
        this.loading = true;
        try {
          await this.addAccount(this.editedItem);
          this.loading = false;
          this.close();

          this.snack = true
          this.snackColor = 'success'
          this.snackText = this.$t('user_has_been_added')

        } catch (e) {
          this.loading = false;
          this.close();

          this.snack = true
          this.snackColor = 'error'
          this.snackText = this.$t('user_has_not_been_added')

          console.error(e);
        }
      }
    },
  },
  filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    }
};
</script>