import firebase from 'firebase/compat';
import 'firebase/compat/firestore';

const sms = {
    namespaced: true,
    state: {
        last10Messages: [],
        next10Messages: [],
        futureMessagesCount: 0,
    },
    getters: {
        last10Messages: state => state.last10Messages,
        next10Messages: state => state.next10Messages,
        futureMessagesCount: state => state.futureMessagesCount,
    },
    mutations: {
        SET_LAST_10_MESSAGES(state, messages) {
            state.last10Messages = messages;
        },
        SET_NEXT_10_MESSAGES(state, messages) {
            state.next10Messages = messages;
        },
        SET_FUTURE_MESSAGES_COUNT(state, count) {
            state.futureMessagesCount = count;
        },
    },
    actions: {
        fetchLast10Messages({ commit }, userId) {
            const now = Date.now();
            const db = firebase.firestore();
            const collectionRef = db.collection('events');
            collectionRef.where('userId', '==', userId).where('sendAt', '<', now).orderBy('sendAt', 'desc').limit(10).get()
                .then(snapshot => {
                const messages = [];
                snapshot.forEach(doc => {
                    const data = doc.data();
                    const sendAt = new Date(data.sendAt).toLocaleString();
                    messages.push({ id: doc.id, sendAt, to: data.to });
                });
                console.log(messages);
                commit('SET_LAST_10_MESSAGES', messages);
                })
                .catch(error => {
                console.error('Wystąpił błąd:', error);
                });
        },
        fetchNext10Messages({ commit }, userId) {
            const now = Date.now();
            const db = firebase.firestore();
            const collectionRef = db.collection('events');
            collectionRef.where('userId', '==', userId).where('sendAt', '>', now).orderBy('sendAt', 'asc').limit(10).get()
                .then(snapshot => {
                const messages = [];
                snapshot.forEach(doc => {
                    const data = doc.data();
                    const sendAt = new Date(data.sendAt).toLocaleString();
                    messages.push({ id: doc.id, sendAt, to: data.to });
                });
                commit('SET_NEXT_10_MESSAGES', messages);
                })
                .catch(error => {
                console.error('Wystąpił błąd:', error);
                });
        },
        fetchFutureMessagesCount({ commit }, userId) {
            const now = Date.now();
            const db = firebase.firestore();
            const collectionRef = db.collection('sms');
            collectionRef.where('userId', '==', userId).where('sendAt.msSinceEpoch', '>', now).get()
                .then(snapshot => {
                commit('SET_FUTURE_MESSAGES_COUNT', snapshot.size);
                })
                .catch(error => {
                console.error('Wystąpił błąd:', error);
                });
        },
    }
};
export default sms;
