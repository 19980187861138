<template>
  <div class="row">
    <div class="col">
      <v-container>
        <v-switch
    v-model="billingCycle"
    hide-details
    true-value="year"
    false-value="month"
    :label="this.$t('monthly_yearly')"
  ></v-switch>
        <v-row class="d-flex align-center">
        <v-col cols="12" md="6" class="d-flex align-center justify-center">
</v-col>
</v-row>
    <v-row>
      <v-col cols="12" md="4" v-for="(product, index) in products" :key="index + '-price'">
        <v-card class="mb-5">
          <v-card-title class="text-center pa-3">
            {{ product.name }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('save_choosing_yearly_plan') }}
          </v-card-subtitle>
          <v-card-text class="text-center">
            <h2 class="display-2" v-html="getFormattedPriceForCycle(product)"></h2>
          </v-card-text>
          <v-card-text>
            <ul>
                <li>{{ product.stripe_metadata_sms }} {{ $t('sms_per_month') }} </li>
                <li v-for="feature in features" :key="feature">
                  {{ feature }}
                </li>
              </ul>
          </v-card-text>
          <v-card-actions>
            <!--<v-btn color="primary" @click="subscribe(plan.id, billingCycle)">Subscribe</v-btn>-->
            <v-btn 
            color="primary" 
            :disabled="isLoading"
            href="https://workspace.google.com/marketplace/app/notifyapp/256932740718" 
            target="_blank"
            >
            {{ isLoading ? $t('loading') : $t('subscribe') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
      <v-card class="mb-5">
          <v-card-title class="text-center pa-3">
            {{ $t('need_more') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('contact_with_us') }}
          </v-card-subtitle>
          <v-card-text class="text-center">
            <h2 class="display-2" >NotifyApp</h2>
          </v-card-text>
          <v-card-text>
            <ul>
                <li>>1000 {{ $t('sms_per_month') }} </li>
                <li>
                  {{ $t('no_calendar_limit') }}
                </li>
                <li>
                  {{ $t('no_user_limit') }}
                </li>
              </ul>
          </v-card-text>
          <v-card-actions>
            <!--<v-btn color="primary" @click="subscribe(plan.id, billingCycle)">Subscribe</v-btn>-->
            <v-btn
            color="primary"
            :disabled="isLoading"
            href="https://workspace.google.com/marketplace/app/notifyapp/256932740718" 
            target="_blank"
            >
            {{ isLoading ? $t('loading') : $t('subscribe') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>

    </v-row>
  </v-container>
    </div>
  
  </div>
</template>

<script>

import {
  getFirestore,
 // getDocs,
  //where,
  //query,
  collection,
  addDoc,
  onSnapshot,
} from "firebase/firestore";

//import { firestore } from "firebase/compat/app";

//import { firebaseAuth } from "@/firebase";

import firebase from "firebase/compat";
import "firebase/compat/firestore";
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      products: [],
      isLoading: false,
      billingCycle: 'month',
      currency: 'pln',
      currencies: [
        { text: 'Pln', value: 'pln' },  
        { text: 'Euro', value: 'eur' },
        { text: 'Usd', value: 'usd' },
      ],
      features: [
        this.$t('no_calendar_limit'),
        this.$t('no_user_limit'),
      ]
    }
  },
  mounted() {
    this.fetchProducts();
    this.selectCurrency();
    //this.products = [];
  },
  computed:{
    ...mapGetters({
        user: 'auth/user'
    }),
  },
  methods: {
    selectCurrency(){
      // get language from browser and set currency to pln if pl otherwise to euro, but only for EU countries 
      // make array with EU countries and check if language is in this array
      // come on, it's not so hard
      const eu_countries = ['pl','de','fr','it','es','pt','nl','be','el','at','se','fi','dk','ie','hr','lt','lv','ee','cz','sk','hu','ro','bg','si','lu','mt','cy'];

      const language = navigator.language.split('-')[0];
      if(language === 'pl'){
        this.currency = 'pln';
      }else if(eu_countries.includes(language)){
        this.currency = 'eur';
      }else{
        this.currency = 'usd';
      }
    },
    async fetchProducts() {
      const productsQuery = await firebase.firestore().collection("products")
        .where("active", "==", true)
        .where('stripe_metadata_app','==','notify')
        .orderBy('name', 'asc');
      
      productsQuery.get().then(snapshot => {
        snapshot.forEach(async (doc) => {
          const pricesQuerySnap = await firebase.firestore().collection("products").doc(doc.id).collection("prices").get();
          this.products.push({
            id: doc.id,
            ...doc.data(),
            prices: pricesQuerySnap.docs.map((price) => {
              return {
                id: price.id,
                ...price.data(),
              };
            }),
          });
        });
      });
    },

    async createSub(priceId) {
      this.isLoading = true;
      const db = getFirestore();
      const collectionRef = collection(
        db,
        "customers",
        this.user.data.uid,
        "checkout_sessions"
      );

      const docRef = await addDoc(collectionRef, {
        price: priceId,
        allow_promotion_codes: true,
        automatic_tax: true,
        tax_id_collection: true,
        collect_shipping_address: true,
        success_url: window.location.href,
        cancel_url: window.location.href,
      });

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();

        if (error) {
          console.error(`An error occured: ${error.message}`);
          this.isLoading = false;
        }

        if (url) {
          window.location.assign(url);
        }
      });
    },
    getPriceForCycle(product) {
      let price = product.prices.find(o => ((o.interval == this.billingCycle) && (o.currency == this.currency)));
      return price;
    },
    getFormattedPriceForCycle(product) {
      let price = product.prices.find(o => ((o.interval == this.billingCycle) && (o.currency == this.currency)));
      let curr = "&#36;";
      if (this.currency == 'eur') {
        curr = '&#8364';
      }else if (this.currency == 'pln') {
        curr = '&#122;&#322;';
      }
      return  (price.unit_amount / 100).toFixed(2).toString() + ' ' + curr;
    },
    subscribe(product){
      let price = this.getPriceForCycle(product)
      this.createSub(price.id);
    }
  },
};
</script>