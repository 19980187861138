import firebase from 'firebase/compat';
import 'firebase/compat/firestore';

const accounts = {
    namespaced: true,
    state: {
        accounts: [],
        sms: {
            current: 0,
            max: 10
        },
        alphaId: ''
    },
    getters: {
        getAccounts: (state) => {
            return state.accounts;
        },
        getSMS: (state) => {
            return state.sms
        },
        getAlphaId: (state) => {
            return state.alphaId
        }
    },
    mutations: {
        SET_ACCOUNTS(state, accounts) {
            state.accounts = accounts;
        },
        ADD_ACCOUNT(state, account) {
            state.accounts.push(account);
        },
        UPDATE_ACCOUNT(state, payload) {
            Object.assign(state.accounts[payload.index], payload.account);
        },
        REMOVE_ACCOUNT(state, account) {
            const index = state.accounts.indexOf(account);
            state.accounts.splice(index, 1);
        },
        SET_SMS(state, sms) {
            state.sms = sms
        },
        SET_ALPHAID(state, alphaId) {
            state.alphaId = alphaId
        }
    },
    actions: {
        async addAccount({commit, rootState}, account) {
            account['rootUser'] = rootState.auth.user.data.uid;
            const docRef = await firebase.firestore().collection('accounts').add(account)
            //const docRef = await firebase.firestore().collection('users').doc(rootState.auth.user.data.uid).collection('accounts').add(account);
            account.id = docRef.id;
            commit('ADD_ACCOUNT', account);
        },
        async loadAccounts({commit, rootState}) {
            //const querySnapshot = await firebase.firestore().collection('users').doc(rootState.auth.user.data.uid).collection('accounts').get();
            
            
            const querySnapshot = await firebase.firestore()
                .collection('accounts')
                .where("rootUser",'==',rootState.auth.user.data.uid)
                //.where("email","!=",rootState.auth.user.data.email)
                .get();
            let account;
            let accounts = querySnapshot.docs.map(function (doc) {
                    if (doc.data()['email'] !== rootState.auth.user.data.email){
                        account = doc.data();
                        account.id = doc.id;
                        return account;
                    }else {
                        return null
                    }
            }).filter(elements => {
                return elements !== null;
               });
            commit('SET_ACCOUNTS', accounts);
        },
        async updateAccount({commit}, payload) {
            await firebase.firestore().collection('accounts').doc(payload.account.id).set(payload.account);
            //await firebase.firestore().collection('users').doc(rootState.auth.user.data.uid).collection('accounts').doc(payload.account.id).set(payload.account);
            commit('UPDATE_ACCOUNT', payload);
        },
        async removeAccount({commit}, account) {
            await firebase.firestore().collection('accounts').doc(account.id).delete();
            //await firebase.firestore().collection('users').doc(rootState.auth.user.data.uid).collection('accounts').doc(account.id).delete();
            commit('REMOVE_ACCOUNT', account);
        },
        async loadSMS ({commit, rootState}) {
            let sms = await firebase.firestore().collection('users').doc(rootState.auth.user.data.uid).get();
            commit('SET_SMS',sms.data()['usage']);
        },
        async loadAlphaId ({commit, rootState}) {
            let alphaId = await firebase.firestore().collection('users').doc(rootState.auth.user.data.uid).get();
            console.log()   
            commit('SET_ALPHAID', alphaId.data()['alphaId']); 
        },
        async updateAlphaId({commit, rootState}, payload) {
            await firebase.firestore().collection('users').doc(rootState.auth.user.data.uid).update({alphaId: payload});
            commit('SET_ALPHAID', payload); 
        }
    }
}

export default accounts;