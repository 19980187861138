import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/compat/app';
import Login from '../views/Login';
import Terms from '../views/Terms';
import Privacy from '../views/Privacy';
import Help from '../views/Help';
//import Home from '../views/Home'
import HomeNew from '../views/HomeNew';

import Dashboard from '../views/admin/Dashboard';
import Accounts from '../views/admin/Accounts';
import Plans from '../views/admin/Plans';
//import Settings from '../views/admin/Settings';
import Addons from '../views/admin/Addons';
import Support from '../views/admin/Support';
import Sender from '../views/admin/Sender';

Vue.use(VueRouter)

const routes = [
  {
    // Home Page
    path: '/',
    component: HomeNew
  },
  {
    // Terms
    path: '/terms',
    component: Terms
  },
  {
    // Privacy
    path: '/privacy',
    component: Privacy
  },
  {
    // Help
    path: '/help',
    component: Help
  },
  {
    // Login Page
    path: '/login',
    component: Login
  },
  {
    path: '/admin',
    component: () => import('../views/admin/AdminHome.vue'),
    children: [
      { path: '', component: Dashboard },
      { path: 'users', component: Accounts },
      { path: 'plans', component: Plans },
      //{ path: 'settings', component: Settings},
      { path: 'sender', component: Sender},
      { path: 'addons', component: Addons},
      { path: 'support', component: Support}
    ],
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!(firebase.auth().currentUser)) {
      next({name: '/login'});
    } 
    else {
      next();
    }
  }
  else if(to.path == '/login') {
    if(firebase.auth().currentUser) {
      next({path: '/admin'});
    }
    else {
      next();
    }
  }
  else {
    next();
  }
});

export default router
