module.exports = {
    save_your_time: 'Сэкономьте свое время',
    welcome: 'Добро пожаловать',
    hello: 'Привет',
    no_show_reduction: 'Уменьшение числа непришедших',
    manage_reminders: 'Управление напоминаниями как профессионал из простой и интуитивной панели',
    do_you_know_that: 'Знаете ли вы, что?',
    app_description_short: 'Приложение для отправки SMS-напоминаний о встречах из календаря Google',
    more_about_product: 'Больше о продукте',
    personal_assistant: 'Персональный помощник по времени',
    personal_assistant_text: 'Notifyapp - современное приложение для управления временем и отправки напоминаний о встречах посредством SMS. Всегда будьте вовремя!',
    forget_about_lateness: 'Забудьте о задержках',
    forget_about_lateness_text: 'Благодаря Notifyapp вы избежите ненужных задержек и всегда будете готовы к встрече. Автоматические SMS-напоминания помогут вам быть пунктуальным.',
    less_no_shows: 'Меньше непришедших',
    less_no_shows_text: 'Notifyapp помогает уменьшить количество непришедших на встречи. Благодаря SMS-напоминаниям вы сможете быстро реагировать и отменять встречи при необходимости.',
    simple_use: 'Простое и интуитивное использование',
    simple_use_text: 'Notifyapp - простое в использовании приложение, доступное каждому. Подключитесь к учетной записи Google и начните добавлять встречи. Автоматические SMS-напоминания работают в фоновом режиме.',
    brand_building: 'Построение бренда и отзывы клиентов',
    brand_building_text: 'Notifyapp предлагает дополнительные функции, которые помогают в построении бренда и сборе отзывов от клиентов. После встречи отправляются запросы на получение отзывов, что способствует развитию вашего бизнеса.',
    get_addon: 'Получить дополнение',
    get_addon_text: 'Пожалуйста, выберите подходящее расширение, и вы будете автоматически перенаправлены в Маркетплейс Google, где сможете его активировать. После установки наши расширения автоматически добавятся в ваш календарь в каждом браузере, используя тот же пакет SMS.',
    get_addon_notify: '"Уменьшите количество невыходов!" Отправляйте автоматические напоминания о встречах по СМС вашим клиентам прямо из вашего календаря Google!',
    get_addon_opinion: 'Получите больше отзывов на Google! Отправляйте автоматические напоминания о послевстречных отзывах по СМС вашим клиентам прямо из Google Календаря™.',
    navigation_home: 'Главная',
    navigation_product: 'Продукт',
    navigation_download: 'Загрузка',
    navigation_pricing: 'Цены',
    navigation_contact: 'Контакт',
    navigation_login: 'Войти',
    monthly_yearly: 'Ежемесячно / Ежегодно',
    save_choosing_yearly_plan: 'Сэкономьте 20%, выбрав годовой план',
    subscribe: 'Подписаться',
    loading: 'Загрузка...',
    need_more: 'Вам нужно больше?',
    contact_with_us: 'Свяжитесь с нами!',
    sms_per_month: 'SMS в месяц',
    no_calendar_limit: 'без ограничения календарей',
    no_user_limit: 'без ограничения пользователей',
    contact: 'Контакт',
    contact_text: 'У вас есть вопросы? Свяжитесь с нами.',
    we_answer_your_questions: 'Мы ответим на все ваши вопросы и сомнения',
    name: 'Имя',
    email: 'Email',
    message: 'Сообщение',
    send: 'Отправить',
    close: 'Закрыть',
    name_is_required: 'Имя обязательно',
    email_is_required: 'Email обязателен',
    email_is_invalid: 'Некорректный Email',
    message_is_required: 'Сообщение обязательно',
    message_sent: 'Спасибо за сообщение',
    message_not_sent: 'Ошибка при отправке сообщения',
    terms: 'Условия использования',
    privacy_policy: 'Политика конфиденциальности',
    users: 'Пользователи',
    loading_please_wait: 'Загрузка, пожалуйста, подождите...',
    add_user: 'Добавить пользователя',
    user_email: 'Email пользователя',
    cancel: 'Отмена',
    save: 'Сохранить',
    new_user: 'Новый пользователь',
    edit_user: 'Редактирование пользователя',
    account_has_been_deleted: 'Учетная запись была удалена',
    account_could_not_be_deleted: 'Не удалось удалить учетную запись',
    delete_item_are_you_sure: 'Вы уверены, что хотите удалить этот элемент?',
    user_has_been_updated: 'Пользователь был обновлен',
    user_has_not_been_updated: 'Пользователь не был обновлен',
    user_has_been_added: 'Пользователь был добавлен',
    user_has_not_been_added: 'Пользователь не был добавлен',
    menu_dashboard: 'Панель управления',
    menu_users: 'Пользователи',
    menu_plans: 'Планы',
    menu_settings: 'Настройки',
    menu_addons: 'Дополнения',
    menu_support: 'Поддержка',
    loading_account_information: 'Загрузка информации об учетной записи...',
    max_11_chars: 'Максимум 11 символов',
    min_3_chars: 'Минимум 3 символа',
    invalid_alphaid: 'Некорректный альфа-ID отправителя. Он должен содержать хотя бы одну букву. Допустимыми символами являются прописные и строчные буквы, цифры, -, _, +, & и пробел.',
    settings: 'Настройки',
    subscribed_account: 'Подписанная учетная запись',
    subscription_cancel_at_period_end: 'Подписка будет отменена в конце текущего периода',
    current_period_start: 'Текущий период начался',
    current_period_end: 'Текущий период закончится',
    manage_subscription: 'Управление подпиской',
    subscription_plans: 'Планы подписки',
    welcome_dialog_title: "Приветственный бонус: 10 SMS",
    welcome_dialog_subtitle: "Спасибо за регистрацию. Мы добавили 10 SMS на ваш аккаунт. Вы можете использовать их для тестирования нашего сервиса.",
    welcome_dialog_text: "Установите наше дополнение Google Calendar™, чтобы попробовать его. Ниже вы найдете ссылку на маркетплейс Google Workspace. Если у вас есть вопросы, пожалуйста, свяжитесь с нами по адресу: ",
    back_to_home_page: "Вернуться на главную страницу",
    feel_free_to_ask: "Если у вас возникнут вопросы, не стесняйтесь спрашивать. Используйте форму ниже, чтобы связаться с нами, или напишите электронное письмо на адрес support@notifyapp.cloud.",
    please_email_us: "Пожалуйста, напишите нам по электронной почте:",
    users_information: "Здесь у вас есть возможность добавить дополнительных пользователей в свою команду. Эти люди автоматически будут подключены к вашим лицензиям и пакетам. Они будут иметь такой же доступ к приложению, как и вы, но помните, что вы останетесь администратором. Если вы не добавите никого, только вы сможете отправлять текстовые сообщения своим клиентам.",
    menu_sender: 'Отправитель',
    sender_information: 'Эти настройки позволяют настроить отображаемое имя отправителя ваших SMS-сообщений вместо номера телефона. Однако обратите внимание, что в некоторых странах эта опция может быть ограничена из-за правовых регуляций. Если это разрешено, создайте отличительное имя отправителя, соответствующее вашему бренду. Наслаждайтесь гибкостью передачи вашего сообщения с помощью узнаваемого отправителя! Оставайтесь на связи с персонализированным подходом.',
    sender_alphaid: 'Имя отправителя SMS',
    check_recipient_country: 'Проверить страну получателя',
    check_recipient_country_info: 'Выберите страну получателя и проверьте, доступно ли настраиваемое имя отправителя.',
    check_recipient_country_true: 'В этой стране разрешены настраиваемые имена отправителей.',
    check_recipient_country_false: 'В этой стране не разрешены настраиваемые имена отправителей. Отправителем сообщения будет следующий номер: +1 3608032010. Если вас интересует индивидуальный номер, свяжитесь с нами.',
    select_country: 'Выберите страну',
    choose: "Выбираю",
    plans_info_no_test_sms: "Тестируйте приложение без покупки пакета! Новые пользователи получают 10 бесплатных текстовых сообщений для тестирования. Если вы только что зарегистрировались, не забудьте обновить дополнение Google Calendar™.",
    dashboard_info: 'Добро пожаловать в вашу панель управления NotifyApp! Здесь вы можете просматривать и управлять всей вашей важной информацией сразу. Это включает в себя количество отправленных сообщений, запланированных сообщений и членов вашей команды. Будьте в курсе и отслеживайте свои коммуникационные метрики.',
    dashboard_messages_amount: 'Количество ваших сообщений',
    dashboard_teammates: 'Ваши коллеги по команде',
    dashboard_scheduled_messages: 'Запланированные сообщения',
    dashboard_your_plan: 'Ваш план',
    dashboard_free_sms: '10 бесплатных SMS',
    dashboard_upgrade: 'Перейдите на платный план для большего.',
    dashboard_last_messages: 'Последние 10 сообщений',
    dashboard_next_messages: 'Следующие 10 сообщений',
    dashboard_date: 'Дата',
    dashboard_recipient: 'Получатель',
    dashboard_next_invoice: 'Следующий счет: ',
    dashboard_no_messages: 'Нет сообщений',
  };
  