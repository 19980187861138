<template>
<div>
<v-card class="card-spacing">
  <v-card-title> {{ $t('menu_support') }} </v-card-title>
  <v-card-text> {{ $t('feel_free_to_ask') }}</v-card-text>
</v-card>
<v-row>

<v-col cols="12" md="6">
  <contact/>
</v-col>

<v-col cols="12" md="6">
  <iframe id="ytplayer" type="text/html" width="100%" height="405"
    :src="currentVideoLink"
    frameborder="0" allowfullscreen>
  </iframe>
</v-col>

</v-row>
</div>
</template>

<script>
//import firebase from "firebase/compat";
import "firebase/compat/firestore";

import contact from "../../components/ContactSectionAdmin.vue"; 
//../components/ContactSection";

export default {
  components: {
    contact
  },
  data() {
    return {
      videoLinks: {
        'en': 'https://www.youtube.com/embed/vyOU9xN8zZc?loop=1&modestbranding=1&color=white',
        'pl': 'https://www.youtube.com/embed/Z5wPDtDgxQg?loop=1&modestbranding=1&color=white',
        'es': 'https://www.youtube.com/embed/ao2hSG74Vi4?loop=1&modestbranding=1&color=white',
        'fr': 'https://www.youtube.com/embed/e4U1Z2BcLFk?loop=1&modestbranding=1&color=white',
        'it': 'https://www.youtube.com/embed/kIe9o08pyBA?loop=1&modestbranding=1&color=white',
        'de': 'https://www.youtube.com/embed/ikCiWIbXYhs?loop=1&modestbranding=1&color=white'
      },
      currentVideoLink: ''
    };
  },
  mounted() {
    this.currentVideoLink = this.videoLinks[this.$i18n.locale] || this.videoLinks['en'];
  },
  computed: {
  },
  watch: {
    '$i18n.locale'(newLocale) {
      this.currentVideoLink = this.videoLinks[newLocale] || this.videoLinks['en'];
    }
  },
  created() {
  },
  methods: {
  },
};
</script>

<style>
/* Dodajemy styl CSS dla elementu <v-card> */
.card-spacing {
  margin-bottom: 20px; /* Możesz dostosować tę wartość */
}
</style>