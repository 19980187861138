module.exports = {
    save_your_time: 'Sparen Sie Zeit',
    welcome: 'Willkommen',
    hello: 'Hallo',
    no_show_reduction: 'Reduzierung der Anzahl von Nichterscheinungen',
    manage_reminders: 'Verwalten Sie Erinnerungen wie ein Profi mit einem einfachen und intuitiven Dashboard',
    do_you_know_that: 'Wussten Sie, dass?',
    app_description_short: 'App zum Senden von SMS-Erinnerungen für Termine im Google Kalender',
    more_about_product: 'Mehr über das Produkt',
    personal_assistant: 'Persönlicher Zeitassistent',
    personal_assistant_text: 'Notifyapp - eine moderne App zur Zeitverwaltung und zum Senden von Terminerinnerungen per SMS. Seien Sie immer pünktlich!',
    forget_about_lateness: 'Vergessen Sie Verspätungen',
    forget_about_lateness_text: 'Mit Notifyapp vermeiden Sie unnötige Verspätungen und sind immer auf Meetings vorbereitet. Automatische SMS-Erinnerungen helfen Ihnen, pünktlich zu sein.',
    less_no_shows: 'Weniger Nichterscheinungen',
    less_no_shows_text: 'Notifyapp hilft dabei, die Anzahl der nicht besuchten Meetings zu reduzieren. Dank SMS-Erinnerungen können Sie schnell reagieren und ein Meeting absagen, wenn es nötig ist.',
    simple_use: 'Einfache und intuitive Bedienung',
    simple_use_text: 'Notifyapp - eine benutzerfreundliche App, die für jeden zugänglich ist. Verbinden Sie sie mit Ihrem Google-Konto und fügen Sie Termine hinzu. Automatische SMS-Erinnerungen funktionieren im Hintergrund.',
    brand_building: 'Markenaufbau und Kundenbewertungen',
    brand_building_text: 'Notifyapp bietet zusätzliche Funktionen, die beim Markenaufbau und der Sammlung von Kundenbewertungen helfen. Nach einem Meeting werden Bewertungsanfragen gesendet, um das Unternehmen weiterzuentwickeln.',
    get_addon: 'Add-On herunterladen',
    get_addon_text: 'Bitte wählen Sie eine Erweiterung aus und Sie werden automatisch zum Google Marketplace weitergeleitet, wo Sie sie aktivieren können. Sobald sie installiert ist, werden unsere Erweiterungen automatisch zu Ihrem Kalender in jedem Browser hinzugefügt und verwenden dasselbe SMS-Paket.',
    get_addon_notify: '"Reduzieren Sie Ausfälle!" Senden Sie automatische SMS-Terminerinnerungen an Ihre Kunden direkt aus Ihrem Google-Kalender!',
    get_addon_opinion: 'Erhalten Sie mehr Bewertungen auf Google! Senden Sie automatische Nachbesprechungs-Erinnerungen per SMS an Ihre Kunden direkt aus dem Google-Kalender™.',
    navigation_home: 'Startseite',
    navigation_product: 'Produkt',
    navigation_download: 'Herunterladen',
    navigation_pricing: 'Preise',
    navigation_contact: 'Kontakt',
    navigation_login: 'Anmelden',
    monthly_yearly: 'Monatlich / Jährlich',
    save_choosing_yearly_plan: 'Sparen Sie 20% mit dem Jahresplan',
    subscribe: 'Abonnieren',
    loading: 'Laden...',
    need_more: 'Benötigen Sie mehr?',
    contact_with_us: 'Kontaktieren Sie uns!',
    sms_per_month: 'SMS pro Monat',
    no_calendar_limit: 'Keine Begrenzung für Kalender',
    no_user_limit: 'Keine Begrenzung für Benutzer',
    contact: 'Kontakt',
    contact_text: 'Haben Sie Fragen? Nehmen Sie Kontakt mit uns auf.',
    we_answer_your_questions: 'Wir beantworten Ihre Fragen und Bedenken',
    name: 'Name',
    email: 'E-Mail',
    message: 'Nachricht',
    send: 'Senden',
    close: 'Schließen',
    name_is_required: 'Name ist erforderlich',
    email_is_required: 'E-Mail ist erforderlich',
    email_is_invalid: 'Ungültige E-Mail-Adresse',
    message_is_required: 'Nachricht ist erforderlich',
    message_sent: 'Vielen Dank für Ihre Nachricht',
    message_not_sent: 'Fehler beim Senden der Nachricht',
    terms: 'Allgemeine Geschäftsbedingungen',
    privacy_policy: 'Datenschutzrichtlinie',
    users: 'Benutzer',
    loading_please_wait: 'Laden, bitte warten...',
    add_user: 'Benutzer hinzufügen',
    user_email: 'Benutzer-E-Mail',
    cancel: 'Abbrechen',
    save: 'Speichern',
    new_user: 'Neuer Benutzer',
    edit_user: 'Benutzer bearbeiten',
    account_has_been_deleted: 'Konto wurde gelöscht',
    account_could_not_be_deleted: 'Konto konnte nicht gelöscht werden',
    delete_item_are_you_sure: 'Möchten Sie dieses Element wirklich löschen?',
    user_has_been_updated: 'Benutzer wurde aktualisiert',
    user_has_not_been_updated: 'Benutzer wurde nicht aktualisiert',
    user_has_been_added: 'Benutzer wurde hinzugefügt',
    user_has_not_been_added: 'Benutzer wurde nicht hinzugefügt',
    menu_dashboard: 'Dashboard',
    menu_users: 'Benutzer',
    menu_plans: 'Pläne',
    menu_settings: 'Einstellungen',
    menu_addons: 'Add-Ons',
    menu_support: 'Unterstützung',
    loading_account_information: 'Kontoinformationen werden geladen...',
    max_11_chars: 'Maximal 11 Zeichen',
    min_3_chars: 'Mindestens 3 Zeichen',
    invalid_alphaid: 'Ungültige AlphaId. Muss mindestens einen Buchstaben enthalten. Erlaubte Zeichen: Großbuchstaben, Kleinbuchstaben, Zahlen, -, _, +, & und Leerzeichen.',
    settings: 'Einstellungen',
    subscribed_account: 'Abonniertes Konto',
    subscription_cancel_at_period_end: 'Das Abonnement wird am Ende des Abrechnungszeitraums gekündigt',
    current_period_start: 'Der aktuelle Abrechnungszeitraum begann am',
    current_period_end: 'Der aktuelle Abrechnungszeitraum endet am',
    manage_subscription: 'Abonnement verwalten',
    subscription_plans: 'Abonnementpläne',
    welcome_dialog_title: "Willkommensbonus: 10 SMS",
    welcome_dialog_subtitle: "Vielen Dank für Ihre Registrierung. Wir haben Ihrem Konto 10 SMS hinzugefügt. Sie können sie verwenden, um unseren Service zu testen.",
    welcome_dialog_text: "Installieren Sie unser Google Calendar™-Add-On, um es auszuprobieren. Unten finden Sie einen Link zum Google Workspace Marketplace. Bei Fragen kontaktieren Sie uns bitte unter ",
    back_to_home_page: "Zurück zur Startseite",
    feel_free_to_ask: 'Wenn Sie Fragen haben, zögern Sie nicht, uns zu fragen. Verwenden Sie bitte das unten stehende Formular, um uns zu kontaktieren oder senden Sie eine E-Mail an support@notifyapp.cloud.',
    users_information: "Hier haben Sie die Möglichkeit, zusätzliche Benutzer zu Ihrem Team hinzuzufügen. Diese Personen werden automatisch mit Ihren Lizenzen und Paketen verbunden. Sie haben den gleichen Zugriff auf die Anwendung wie Sie, jedoch bleiben Sie der Administrator. Wenn Sie niemanden hinzufügen, können nur Sie Textnachrichten an Ihre Kunden senden.",
    menu_sender: 'Absender',
    sender_information: 'Diese Einstellungen ermöglichen es Ihnen, den Namen anzupassen, der als Absender Ihrer SMS-Nachrichten angezeigt wird, anstelle der Telefonnummer. Bitte beachten Sie jedoch, dass diese Option in einigen Ländern aufgrund rechtlicher Bestimmungen eingeschränkt sein kann. Wenn es erlaubt ist, erstellen Sie einen eindeutigen Absendernamen, der zu Ihrer Marke passt. Genießen Sie die Flexibilität, Ihre Botschaft über einen erkennbaren Absender zu übermitteln! Bleiben Sie mit einer persönlichen Note verbunden.',
    sender_alphaid: 'SMS-Absendername',
    check_recipient_country: 'Empfängerland überprüfen',
    check_recipient_country_info: 'Wählen Sie das Empfängerland aus und überprüfen Sie, ob ein benutzerdefinierter Absendername verfügbar ist.',
    check_recipient_country_true: 'In diesem Land sind benutzerdefinierte Absendernamen möglich.',
    check_recipient_country_false: 'In diesem Land sind keine benutzerdefinierten Absendernamen möglich. Der Absender der Nachricht wird folgende Nummer sein: +1 3608032010. Wenn Sie an einer dedizierten Nummer interessiert sind, kontaktieren Sie uns bitte.',
    select_country: 'Land auswählen',
    choose: "Wähle",
    plans_info_no_test_sms: "Testen Sie die Anwendung ohne den Paketkauf! Neue Benutzer erhalten 10 kostenlose Test-SMS-Nachrichten. Wenn Sie sich gerade registriert haben, denken Sie daran, das Google Calendar™-Addon zu aktualisieren.",
    dashboard_info: 'Willkommen zu Ihrem NotifyApp Dashboard! Hier können Sie alle Ihre wichtigen Informationen auf einen Blick anzeigen und verwalten. Dazu gehören die Anzahl der gesendeten Nachrichten, geplante Nachrichten und Ihre Teammitglieder. Bleiben Sie informiert und behalten Sie Ihre Kommunikationsmetriken im Blick.',
    dashboard_messages_amount: 'Ihre Nachrichtenmenge',
    dashboard_teammates: 'Ihre Teamkollegen',
    dashboard_scheduled_messages: 'Geplante Nachrichten',
    dashboard_your_plan: 'Ihr Plan',
    dashboard_free_sms: 'Kostenlose 10 SMS',
    dashboard_upgrade: 'Wechseln Sie zu einem kostenpflichtigen Plan für mehr.',
    dashboard_last_messages: 'Letzte 10 Nachrichten',
    dashboard_next_messages: 'Nächste 10 Nachrichten',
    dashboard_date: 'Datum',
    dashboard_recipient: 'Empfänger',
    dashboard_next_invoice: 'Nächste Rechnung: ',
    dashboard_no_messages: 'Keine Nachrichten',
  };
  