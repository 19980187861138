module.exports = {
    save_your_time: '节省您的时间',
    welcome: '欢迎',
    hello: '你好',
    no_show_reduction: '减少未出席次数',
    manage_reminders: '通过简单直观的面板管理提醒',
    do_you_know_that: '你知道吗？',
    app_description_short: '用于发送来自Google日历的会议短信提醒的应用程序',
    more_about_product: '了解更多关于产品',
    personal_assistant: '个人时间助手',
    personal_assistant_text: 'Notifyapp - 现代化的时间管理和通过短信发送会议提醒的应用程序。永远不要迟到！',
    forget_about_lateness: '忘掉迟到',
    forget_about_lateness_text: '使用Notifyapp，您可以避免不必要的迟到，始终准备好会议。自动发送的短信提醒将帮助您准时到达。',
    less_no_shows: '减少未出席次数',
    less_no_shows_text: 'Notifyapp帮助减少未出席的会议次数。通过短信提醒，您可以快速响应并取消会议（如果需要）。',
    simple_use: '简单易用',
    simple_use_text: 'Notifyapp - 适用于每个人的易于使用的应用程序。连接您的Google帐户并添加会议。自动短信提醒在后台运行。',
    brand_building: '品牌建设和客户反馈',
    brand_building_text: 'Notifyapp提供额外功能，帮助您建立品牌并收集客户反馈。会议结束后，将发送反馈请求，有助于您的业务发展。',
    get_addon: '获取附加组件',
    get_addon_text: '请选择适当的扩展，您将自动跳转至Google应用市场，您可以在那里激活它。安装完成后，我们的扩展将自动添加到每个浏览器的日历中，并使用相同的短信套餐。',
    get_addon_notify: '“减少未到场率！” 通过您的Google日历，直接向客户发送自动短信预约提醒！',
    get_addon_opinion: '在Google上获得更多评论！通过短信，直接从您的Google日历向客户发送自动会后反馈提醒！',
    navigation_home: '首页',
    navigation_product: '产品',
    navigation_download: '下载',
    navigation_pricing: '定价',
    navigation_contact: '联系',
    navigation_login: '登录',
    monthly_yearly: '每月/每年',
    save_choosing_yearly_plan: '选择年度计划可节省20%',
    subscribe: '订阅',
    loading: '加载中...',
    need_more: '需要更多吗？',
    contact_with_us: '与我们联系！',
    sms_per_month: '每月短信数',
    no_calendar_limit: '无日历限制',
    no_user_limit: '无用户限制',
    contact: '联系',
    contact_text: '有问题？请与我们联系。',
    we_answer_your_questions: '我们会回答您的问题和疑虑',
    name: '姓名',
    email: '电子邮件',
    message: '留言',
    send: '发送',
    close: '关闭',
    name_is_required: '姓名不能为空',
    email_is_required: '电子邮件不能为空',
    email_is_invalid: '电子邮件无效',
    message_is_required: '留言内容不能为空',
    message_sent: '感谢您的留言',
    message_not_sent: '发送留言时出错',
    terms: '条款',
    privacy_policy: '隐私政策',
    users: '用户',
    loading_please_wait: '加载中，请稍候...',
    add_user: '添加用户',
    user_email: '用户电子邮件',
    cancel: '取消',
    save: '保存',
    new_user: '新用户',
    edit_user: '编辑用户',
    account_has_been_deleted: '帐户已删除',
    account_could_not_be_deleted: '无法删除帐户',
    delete_item_are_you_sure: '确定要删除此项目吗？',
    user_has_been_updated: '用户已更新',
    user_has_not_been_updated: '用户未更新',
    user_has_been_added: '用户已添加',
    user_has_not_been_added: '用户未添加',
    menu_dashboard: '仪表盘',
    menu_users: '用户',
    menu_plans: '计划',
    menu_settings: '设置',
    menu_addons: '附加组件',
    menu_support: '支持',
    loading_account_information: '正在加载帐户信息...',
    max_11_chars: '最多11个字符',
    min_3_chars: '最少3个字符',
    invalid_alphaid: '无效的AlphaId。必须包含至少一个字母。允许使用的字符包括：大小写字母、数字、-、_、+、&和空格。',
    settings: '设置',
    subscribed_account: '订阅帐户',
    subscription_cancel_at_period_end: '订阅将在当前结算周期结束时取消',
    current_period_start: '当前结算周期开始于',
    current_period_end: '当前结算周期结束于',
    manage_subscription: '管理订阅',
    subscription_plans: '订阅计划',
    welcome_dialog_title: "欢迎奖励：10 条短信",
    welcome_dialog_subtitle: "感谢您的注册。我们已经将 10 条短信添加到您的账户中。您可以用它们来测试我们的服务。",
    welcome_dialog_text: "安装我们的 Google Calendar™ 插件来试用它。下面您将找到一个链接，指向 Google Workspace Marketplace。如果您有任何问题，请通过以下方式联系我们： ",
    back_to_home_page: "返回主页",
    feel_free_to_ask: "如果您有任何问题，请随时提问。请使用下面的表单与我们联系，或发送电子邮件至support@notifyapp.cloud。",
    please_email_us: "请发送电子邮件给我们：",
    users_information: "在这里，您可以选择添加额外的用户到您的团队中。这些人将自动与您的许可证和套餐相连。他们将和您一样拥有对应用程序的访问权限，但请记住，您将保持为管理员。如果您不添加任何人，只有您能够向您的客户发送短信。",
    menu_sender: '发件人',
    sender_information: '这些设置可以让您自定义SMS短信的发件人名称，替代手机号码。但请注意，由于法律法规的限制，某些国家可能会限制该选项。如果允许，创建一个与您品牌一致的独特发件人名称。通过一个可识别的发件人灵活传递您的信息！保持个性化的联系。',
    sender_alphaid: 'SMS发件人名称',
    check_recipient_country: '检查收件国家',
    check_recipient_country_info: '请选择收件国家并检查是否允许自定义发件人名称。',
    check_recipient_country_true: '该国家允许自定义发件人名称。',
    check_recipient_country_false: '该国家不允许自定义发件人名称。消息的发件人将是以下号码：+1 3608032010。如果您对专属号码感兴趣，请与我们联系。',
    select_country: '选择国家',
    choose: "选择",
    plans_info_no_test_sms: "在购买套餐前测试应用程序！新用户可获得10条免费的短信测试消息。如果您刚刚注册，请记得更新Google Calendar™插件。",
    dashboard_info: '欢迎来到您的NotifyApp仪表板！在这里，您可以一目了然地查看和管理所有重要信息。这包括已发送的消息数量，计划的消息，以及您的团队成员。保持了解并跟踪您的通信指标。',
    dashboard_messages_amount: '您的消息数量',
    dashboard_teammates: '您的团队成员',
    dashboard_scheduled_messages: '计划的消息',
    dashboard_your_plan: '您的计划',
    dashboard_free_sms: '免费10条短信',
    dashboard_upgrade: '升级到付费计划以获取更多。',
    dashboard_last_messages: '最后10条消息',
    dashboard_next_messages: '接下来的10条消息',
    dashboard_date: '日期',
    dashboard_recipient: '收件人',
    dashboard_next_invoice: '下一张发票：',
    dashboard_no_messages: '没有消息',
  };
  