<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="font-weight-light display-1">{{ $t('contact') }}</h1>
              <h3 class="font-weight-light mt-3">
                {{ $t('contact_text') }}
              </h3>
              <h3 class="font-weight-light mt-3">
                {{ $t('we_answer_your_questions') }}
              </h3>
              <h3 class="font-weight-light">
                Email: support@notifyapp.cloud
              </h3>
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field
                    v-model="name"
                    :rules="nameRules()"
                    :label="this.$t('name')"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="email"
                    :rules="emailRules()"
                    :label="this.$t('email')"
                    required
                ></v-text-field>

                <v-textarea
                    v-model="textArea"
                    :rules="textAreaRules()"
                    :label="this.$t('message')"
                    required
                />

                <v-btn
                    :disabled="!valid"
                    color="primary"
                    :dark="valid"
                    rounded
                    block
                    class="mt-3"
                    @click="submit"
                >
                  {{ $t('send') }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!--
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg"/>
    </div>
    -->
    <v-snackbar
        v-model="snackbar.enabled"
        timeout="3000"
        right
        top
        :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar.enabled = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

</style>

<script>
import firebase from "firebase/compat";
import "firebase/compat/firestore";

export default {
  data: () => ({
    //icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    email: "",
    textArea: "",
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
  methods: {
    nameRules() {
      return [
        (v) => !!v || this.$t('name_is_required'),
      ]
    },
    emailRules() {
      return [
        (v) => !!v || this.$t('email_is_required'),
        (v) => /.+@.+\..+/.test(v) || this.$t('email_is_invalid'),
      ]
    },
    textAreaRules() {
      return [
        (v) => !!v || this.$t('message_is_required'),
      ]
    },
    submit() {
      //const db = getFirestore();
      firebase.firestore().collection("contact_data").add({
        name: this.name,
        email: this.email,
        message: this.textArea
      }).then(() => {
        this.snackbar.text = this.$t('message_sent')
        this.snackbar.color = "success"
        this.snackbar.enabled = true
      }).catch(() => {
        this.snackbar.text = this.$t('message_not_sent')
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      })
    }
  }
};
</script>
