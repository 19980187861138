module.exports = {
    save_your_time: 'Risparmia il tuo tempo',
    welcome: 'Benvenuto',
    hello: 'Ciao',
    no_show_reduction: 'Riduci il numero di appuntamenti mancati',
    manage_reminders: 'Gestisci i promemoria come un professionista da un pannello semplice e intuitivo',
    do_you_know_that: 'Lo sai che?',
    app_description_short: 'App per inviare promemoria via SMS degli appuntamenti dal calendario di Google',
    more_about_product: 'Maggiori informazioni sul prodotto',
    personal_assistant: 'Assistente personale del tempo',
    personal_assistant_text: 'Notifyapp - un\'applicazione moderna per la gestione del tempo e l\'invio di promemoria degli appuntamenti tramite SMS. Arriva sempre in orario!',
    forget_about_lateness: 'Dimentica i ritardi',
    forget_about_lateness_text: 'Grazie a Notifyapp eviterai ritardi inutili e sarai sempre pronto per gli appuntamenti. I promemoria automatici via SMS ti aiuteranno a essere puntuale.',
    less_no_shows: 'Meno appuntamenti saltati',
    less_no_shows_text: 'Notifyapp aiuta a ridurre il numero di appuntamenti a cui non si partecipa. Grazie ai promemoria via SMS, potrai reagire rapidamente e annullare un appuntamento se necessario.',
    simple_use: 'Uso semplice e intuitivo',
    simple_use_text: 'Notifyapp - un\'applicazione facile da usare disponibile per tutti. Collegala al tuo account Google e inizia ad aggiungere appuntamenti. I promemoria automatici via SMS funzionano in background.',
    brand_building: 'Costruzione del marchio e opinioni dei clienti',
    brand_building_text: 'Notifyapp offre funzionalità aggiuntive che aiutano a costruire un marchio e raccogliere opinioni dai clienti. Dopo un appuntamento, vengono invitate le recensioni, contribuendo allo sviluppo dell\'azienda.',
    get_addon: 'Ottieni l\'estensione',
    get_addon_text: 'Per favore, seleziona un\'estensione e sarai automaticamente reindirizzato al Marketplace di Google, dove potrai attivarla. Una volta installate, le nostre estensioni saranno automaticamente aggiunte al tuo calendario su ogni browser, utilizzando lo stesso pacchetto di SMS.',
    get_addon_notify: '"Riduci le assenze!" Invia promemoria automatici di appuntamenti via SMS ai tuoi clienti direttamente dal tuo calendario Google!',
    get_addon_opinion: 'Ottieni più recensioni su Google! Invia promemoria automatici di feedback post-incontro via SMS ai tuoi clienti direttamente dal Calendario Google™.',
    navigation_home: 'Home',
    navigation_product: 'Prodotto',
    navigation_download: 'Download',
    navigation_pricing: 'Prezzi',
    navigation_contact: 'Contatto',
    navigation_login: 'Accedi',
    monthly_yearly: 'Mensile / Annuale',
    save_choosing_yearly_plan: 'Risparmia il 20% scegliendo il piano annuale',
    subscribe: 'Iscriviti',
    loading: 'Caricamento...',
    need_more: 'Hai bisogno di ulteriori informazioni?',
    contact_with_us: 'Contattaci',
    sms_per_month: 'SMS al mese',
    no_calendar_limit: 'Senza limite di calendari',
    no_user_limit: 'Senza limite di utenti',
    contact: 'Contatto',
    contact_text: 'Hai delle domande? Contattaci.',
    we_answer_your_questions: 'Risponderemo a tutte le tue domande e dubbi',
    name: 'Nome',
    email: 'Email',
    message: 'Messaggio',
    send: 'Invia',
    close: 'Chiudi',
    name_is_required: 'Il nome è obbligatorio',
    email_is_required: 'L\'email è obbligatoria',
    email_is_invalid: 'L\'email non è valida',
    message_is_required: 'Il messaggio è obbligatorio',
    message_sent: 'Grazie per il messaggio',
    message_not_sent: 'Errore durante l\'invio del messaggio',
    terms: 'Termini e condizioni',
    privacy_policy: 'Informativa sulla privacy',
    users: 'Utenti',
    loading_please_wait: 'Caricamento in corso, attendere...',
    add_user: 'Aggiungi utente',
    user_email: 'Email dell\'utente',
    cancel: 'Annulla',
    save: 'Salva',
    new_user: 'Nuovo utente',
    edit_user: 'Modifica utente',
    account_has_been_deleted: 'L\'account è stato eliminato',
    account_could_not_be_deleted: 'Impossibile eliminare l\'account',
    delete_item_are_you_sure: 'Sei sicuro di voler eliminare questo elemento?',
    user_has_been_updated: 'L\'utente è stato aggiornato',
    user_has_not_been_updated: 'L\'utente non è stato aggiornato',
    user_has_been_added: 'L\'utente è stato aggiunto',
    user_has_not_been_added: 'L\'utente non è stato aggiunto',
    menu_dashboard: 'Dashboard',
    menu_users: 'Utenti',
    menu_plans: 'Piani',
    menu_settings: 'Impostazioni',
    menu_addons: 'Estensioni',
    menu_support: 'Supporto',
    loading_account_information: 'Caricamento informazioni sull\'account...',
    max_11_chars: 'Massimo 11 caratteri',
    min_3_chars: 'Minimo 3 caratteri',
    invalid_alphaid: 'ID mittente alfanumerico non valido. Deve contenere almeno una lettera. Sono consentite lettere maiuscole, lettere minuscole, numeri, -, _, +, & e spazi.',
    settings: 'Impostazioni',
    subscribed_account: 'Account sottoscritto',
    subscription_cancel_at_period_end: 'L\'abbonamento sarà cancellato alla fine del periodo di fatturazione',
    current_period_start: 'Il periodo di fatturazione attuale è iniziato il',
    current_period_end: 'Il periodo di fatturazione attuale termina il',
    manage_subscription: 'Gestisci abbonamento',
    subscription_plans: 'Piani di abbonamento',
    welcome_dialog_title: "Bonus di benvenuto: 10 SMS",
    welcome_dialog_subtitle: "Grazie per esserti registrato. Abbiamo aggiunto 10 SMS al tuo account. Puoi utilizzarli per testare il nostro servizio.",
    welcome_dialog_text: "Installa la nostra estensione Google Calendar™ per provarla. Qui sotto troverai un link al Google Workspace Marketplace. Se hai domande, contattaci all'indirizzo ",
    back_to_home_page: "Torna alla pagina iniziale",
    feel_free_to_ask: "Se hai domande, sentiti libero di chiedere. Utilizza il modulo qui sotto per contattarci o scrivi una e-mail a support@notifyapp.cloud.",
    please_email_us: "Ti preghiamo di inviarci una e-mail a:",
    users_information: "Qui hai la possibilità di aggiungere utenti aggiuntivi al tuo team. Queste persone saranno automaticamente connesse alle tue licenze e pacchetti. Avranno lo stesso accesso all'applicazione come te, ma ricorda che rimarrai l'amministratore. Se non aggiungi nessuno, sarai l'unico a poter inviare messaggi di testo ai tuoi clienti.",
    menu_sender: 'Mittente',
    sender_information: 'Queste impostazioni ti consentono di personalizzare il nome visualizzato come mittente dei tuoi messaggi SMS, sostituendo il numero di telefono. Tuttavia, tieni presente che questa opzione potrebbe essere limitata in alcuni paesi a causa di regolamenti legali. Se consentito, crea un nome distintivo per il mittente che si allinei al tuo marchio. Goditi la flessibilità di trasmettere il tuo messaggio attraverso un mittente riconoscibile! Resta connesso con un tocco personalizzato.',
    sender_alphaid: 'Nome mittente SMS',
    check_recipient_country: 'Verifica paese destinatario',
    check_recipient_country_info: 'Seleziona il paese destinatario e verifica se è disponibile un nome mittente personalizzato.',
    check_recipient_country_true: 'Questo paese consente nomi mittente personalizzati.',
    check_recipient_country_false: 'Questo paese non consente nomi mittente personalizzati. Il mittente del messaggio sarà il seguente numero: +1 3608032010. Se sei interessato a un numero dedicato, contattaci.',
    select_country: 'Seleziona paese',
    choose: "Scelgo",
    plans_info_no_test_sms: "Prova l'applicazione senza acquistare il pacchetto! I nuovi utenti ricevono 10 messaggi di testo gratuiti. Se ti sei appena registrato, ricorda di aggiornare il componente aggiuntivo Google Calendar™.",
    dashboard_info: "Benvenuto nel tuo cruscotto NotifyApp! Qui, puoi visualizzare e gestire tutte le tue informazioni importanti in un colpo d'occhio. Questo include il numero di messaggi inviati, i messaggi programmati e i membri del tuo team. Rimani informato e tieni traccia delle tue metriche di comunicazione.",
    dashboard_messages_amount: 'Il tuo numero di messaggi',
    dashboard_teammates: 'I tuoi compagni di squadra',
    dashboard_scheduled_messages: 'Messaggi programmati',
    dashboard_your_plan: 'Il tuo piano',
    dashboard_free_sms: '10 SMS gratuiti',
    dashboard_upgrade: 'Passa a un piano a pagamento per di più.',
    dashboard_last_messages: 'Ultimi 10 messaggi',
    dashboard_next_messages: 'Prossimi 10 messaggi',
    dashboard_date: 'Data',
    dashboard_recipient: 'Destinatario',
    dashboard_next_invoice: 'Prossima fattura: ',
    dashboard_no_messages: 'Nessun messaggio',
  };
  