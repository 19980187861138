module.exports = {
    save_your_time: 'Économisez votre temps',
    welcome: 'Bienvenue',
    hello: 'Bonjour',
    no_show_reduction: 'Réduisez le nombre de rendez-vous manqués',
    manage_reminders: 'Gérez vos rappels comme un professionnel depuis un tableau simple et intuitif',
    do_you_know_that: 'Savez-vous que ?',
    app_description_short: "Application pour envoyer des rappels SMS des rendez-vous du calendrier Google",
    more_about_product: 'En savoir plus sur le produit',
    personal_assistant: 'Assistant personnel du temps',
    personal_assistant_text: "Notifyapp - une application moderne pour la gestion du temps et l'envoi de rappels de rendez-vous par SMS. Soyez toujours à l'heure !",
    forget_about_lateness: 'Oubliez les retards',
    forget_about_lateness_text: "Grâce à Notifyapp, évitez les retards inutiles et soyez toujours prêt pour vos rendez-vous. Les rappels automatiques par SMS vous aideront à être ponctuel.",
    less_no_shows: 'Moins de rendez-vous non honorés',
    less_no_shows_text: "Notifyapp aide à réduire le nombre de rendez-vous auxquels vous ne participez pas. Grâce aux rappels par SMS, vous pourrez réagir rapidement et annuler un rendez-vous si nécessaire.",
    simple_use: 'Utilisation simple et intuitive',
    simple_use_text: "Notifyapp - une application facile à utiliser, accessible à tous. Connectez-vous à votre compte Google et commencez à ajouter des rendez-vous. Les rappels automatiques par SMS fonctionnent en arrière-plan.",
    brand_building: 'Construction de la marque et avis des clients',
    brand_building_text: "Notifyapp propose des fonctionnalités supplémentaires pour vous aider à construire votre marque et recueillir les avis des clients. Après chaque rendez-vous, des demandes d'avis sont envoyées, contribuant ainsi au développement de votre entreprise.",
    get_addon: 'Obtenir l\'extension',
    get_addon_text: 'Veuillez sélectionner une extension et vous serez automatiquement dirigé vers la Place de marché Google, où vous pourrez l\'activer. Une fois installées, nos extensions seront automatiquement ajoutées à votre calendrier dans chaque navigateur, en utilisant le même package SMS.',
    get_addon_notify: '"Réduisez les absences !" Envoyez des rappels automatiques de rendez-vous par SMS à vos clients directement depuis votre calendrier Google !',
    get_addon_opinion: 'Obtenez plus d\'avis sur Google ! Envoyez des rappels automatiques de feedback après réunion par SMS à vos clients directement depuis le Calendrier Google™.',
    navigation_home: 'Accueil',
    navigation_product: 'Produit',
    navigation_download: 'Téléchargement',
    navigation_pricing: 'Tarification',
    navigation_contact: 'Contact',
    navigation_login: 'Connexion',
    monthly_yearly: 'Mensuel / Annuel',
    save_choosing_yearly_plan: 'Économisez 20% en choisissant le plan annuel',
    subscribe: 'Souscrire',
    loading: 'Chargement...',
    need_more: 'Besoin de plus d\'informations ?',
    contact_with_us: 'Contactez-nous',
    sms_per_month: 'SMS par mois',
    no_calendar_limit: 'Pas de limite de calendriers',
    no_user_limit: 'Pas de limite d\'utilisateurs',
    contact: 'Contact',
    contact_text: 'Vous avez des questions ? Contactez-nous.',
    we_answer_your_questions: 'Nous répondrons à toutes vos questions et préoccupations',
    name: 'Nom',
    email: 'Email',
    message: 'Message',
    send: 'Envoyer',
    close: 'Fermer',
    name_is_required: 'Le nom est requis',
    email_is_required: 'L\'email est requis',
    email_is_invalid: 'L\'email est invalide',
    message_is_required: 'Le message est requis',
    message_sent: 'Merci pour votre message',
    message_not_sent: 'Erreur lors de l\'envoi du message',
    terms: 'Conditions générales',
    privacy_policy: 'Politique de confidentialité',
    users: 'Utilisateurs',
    loading_please_wait: 'Chargement en cours, veuillez patienter...',
    add_user: 'Ajouter un utilisateur',
    user_email: 'Email de l\'utilisateur',
    cancel: 'Annuler',
    save: 'Enregistrer',
    new_user: 'Nouvel utilisateur',
    edit_user: 'Modifier l\'utilisateur',
    account_has_been_deleted: 'Le compte a été supprimé',
    account_could_not_be_deleted: 'Impossible de supprimer le compte',
    delete_item_are_you_sure: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
    user_has_been_updated: 'L\'utilisateur a été mis à jour',
    user_has_not_been_updated: 'L\'utilisateur n\'a pas été mis à jour',
    user_has_been_added: 'L\'utilisateur a été ajouté',
    user_has_not_been_added: 'L\'utilisateur n\'a pas été ajouté',
    menu_dashboard: 'Tableau de bord',
    menu_users: 'Utilisateurs',
    menu_plans: 'Abonnements',
    menu_settings: 'Paramètres',
    menu_addons: 'Extensions',
    menu_support: 'Support',
    loading_account_information: 'Chargement des informations du compte...',
    max_11_chars: '11 caractères maximum',
    min_3_chars: 'Minimum 3 caractères',
    invalid_alphaid: 'ID expéditeur alphanumérique non valide. Il doit contenir au moins une lettre. Les caractères autorisés sont les lettres majuscules, les lettres minuscules, les chiffres, -, _, +, & et les espaces.',
    settings: 'Paramètres',
    subscribed_account: 'Compte abonné',
    subscription_cancel_at_period_end: 'L\'abonnement sera annulé à la fin de la période de facturation',
    current_period_start: 'La période de facturation actuelle a commencé le',
    current_period_end: 'La période de facturation actuelle se termine le',
    manage_subscription: 'Gérer l\'abonnement',
    subscription_plans: 'Plans d\'abonnement',
    welcome_dialog_title: "Bonus de bienvenue : 10 SMS",
    welcome_dialog_subtitle: "Merci de vous être inscrit. Nous avons ajouté 10 SMS à votre compte. Vous pouvez les utiliser pour tester notre service.",
    welcome_dialog_text: "Installez notre extension Google Calendar™ pour l'essayer. Vous trouverez ci-dessous un lien vers le Google Workspace Marketplace. Si vous avez des questions, contactez-nous à l'adresse ",
    back_to_home_page: "Retour à la page d'accueil",
    feel_free_to_ask: "Si vous avez des questions, n'hésitez pas à nous les poser. Utilisez le formulaire ci-dessous pour nous contacter ou envoyez un e-mail à support@notifyapp.cloud.",
    please_email_us: "Veuillez nous envoyer un e-mail à :",
    users_information: "Ici, vous avez la possibilité d'ajouter des utilisateurs supplémentaires à votre équipe. Ces individus seront automatiquement connectés à vos licences et forfaits. Ils auront le même accès à l'application que vous, mais rappelez-vous que vous resterez l'administrateur. Si vous n'ajoutez personne, vous serez le seul à pouvoir envoyer des messages texte à vos clients.",
    menu_sender: 'Expéditeur',
    sender_information: 'Ces paramètres vous permettent de personnaliser le nom qui s\'affiche en tant qu\'expéditeur de vos messages SMS, remplaçant le numéro de téléphone. Notez cependant que cette option peut être limitée dans certains pays en raison de réglementations légales. Si cela est autorisé, créez un nom d\'expéditeur distinct qui correspond à votre marque. Profitez de la flexibilité de transmettre votre message grâce à un expéditeur reconnaissable ! Restez connecté avec une touche personnalisée.',
    sender_alphaid: 'Nom de l\'expéditeur SMS',
    check_recipient_country: 'Vérifier le pays du destinataire',
    check_recipient_country_info: 'Sélectionnez le pays du destinataire et vérifiez si le nom d\'expéditeur personnalisé est disponible.',
    check_recipient_country_true: 'Ce pays autorise les noms d\'expéditeur personnalisés.',
    check_recipient_country_false: 'Ce pays n\'autorise pas les noms d\'expéditeur personnalisés. L\'expéditeur du message sera le numéro suivant : +1 3608032010. Si vous êtes intéressé par un numéro dédié, veuillez nous contacter.',
    select_country: 'Sélectionner un pays',
    choose: "Choisis",
    plans_info_no_test_sms: "Testez l'application sans acheter le forfait ! Les nouveaux utilisateurs reçoivent 10 SMS gratuits pour les tests. Si vous venez de vous inscrire, n'oubliez pas de mettre à jour l'extension Google Calendar™.",
    dashboard_info: 'Bienvenue sur votre tableau de bord NotifyApp! Ici, vous pouvez voir et gérer toutes vos informations importantes en un coup d\'œil. Cela comprend le nombre de messages envoyés, les messages programmés et les membres de votre équipe. Restez informé et suivez vos métriques de communication.',
    dashboard_messages_amount: 'Votre nombre de messages',
    dashboard_teammates: 'Vos coéquipiers',
    dashboard_scheduled_messages: 'Messages programmés',
    dashboard_your_plan: 'Votre plan',
    dashboard_free_sms: '10 SMS gratuits',
    dashboard_upgrade: 'Passez à un plan payant pour plus.',
    dashboard_last_messages: 'Les 10 derniers messages',
    dashboard_next_messages: 'Les 10 prochains messages',
    dashboard_date: 'Date',
    dashboard_recipient: 'Destinataire',
    dashboard_next_invoice: 'Prochaine facture: ',
    dashboard_no_messages: 'Pas de messages',
  };
  