import Vue from 'vue';
import App from './App.vue';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import CompositionAP from 'vue';
import VueI18n from 'vue-i18n';


Vue.use(VueI18n);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: navigator.language.split('-')[0] || 'en',
  fallbackLocale: 'en',
  messages: {
    en: require('./assets/locales/en.js'),
    pl: require('./assets/locales/pl.js'),
    de: require('./assets/locales/de.js'),
    fr: require('./assets/locales/fr.js'),
    es: require('./assets/locales/es.js'),
    it: require('./assets/locales/it.js'),
    pt: require('./assets/locales/pt.js'),
    ru: require('./assets/locales/ru.js'),
    zh: require('./assets/locales/zh.js'),
    tr: require('./assets/locales/tr.js'),
    nl: require('./assets/locales/nl.js'),
    ko: require('./assets/locales/ko.js'),
  },
});

const configOptions = {
  apiKey: "AIzaSyBppp1cp9hfb9XugtCKVE4y8BxjWJ0DHQg",
  authDomain: "calendar-62d85.firebaseapp.com",
  databaseURL: "https://calendar-62d85-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "calendar-62d85",
  storageBucket: "calendar-62d85.appspot.com",
  messagingSenderId: "256932740718",
  appId: "1:256932740718:web:c33cb567256adc8b08707f",
  measurementId: "G-30GM6VB9GC"
};

firebase.initializeApp(configOptions);

let app;

firebase.auth().onAuthStateChanged(user => {
  store.dispatch('auth/fetchUser', user);
  if(!app) {
    new Vue({
      store,
      router,
      vuetify,
      i18n,
      CompositionAP,
      render: h => h(App)
    }).$mount('#app');
  }
});
