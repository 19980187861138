<template>
    <v-app>
    <v-container class="fill-height" fluid>
                    <router-link to="/" class="text-decoration-none">
            <v-btn text color="white" class="no-bg">
              <v-img src="@/assets/img/left-arrow-login.svg"></v-img> {{ $t('back_to_home_page') }}
            </v-btn>
          </router-link>
      <v-row align="center" justify="center">
        <v-col cols="12"
              sm="4"
              md="4">

              <v-img src="@/assets/img/chmura.svg" width="500" height="100" contain/>
              <v-card class="mx-auto align-items-center" rounded elevation="20" height="200" color="primary" width="300">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title class="headline">Admin Panel</v-toolbar-title>
            </v-toolbar>
            <v-card-actions>
              <v-btn
        color="black"
        width="100%"
        depressed
        outlined
        height="40px"
        class="text--black button-white"
        @click="googleLogin"
      >
        <img
          width="18px"
          height="18px"
          class="mr-6"
          src="@/assets/img/google.svg"
        />
        <span class="text--black font-roboto  ">Sign up with Google</span>
      </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app> 
</template>

<script>
import firebase from 'firebase/compat/app';

export default {
  data() {
    return {
      imgsrc: require('@/assets/img/google.svg'),
      disabled: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      showPassword: false,
      password: '',
      passwordRules: [
        v => !!v || 'Password is required'
      ],
      error: ''
    }
  },
  methods: {
    googleLogin() {
      const provider = new firebase.auth.GoogleAuthProvider()
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(() => {
          this.$router.replace({ path: "/admin" })
        })
    },
    validate() {
      if(this.$refs.form.validate()) {
        this.disabled = true;

        firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.password)
            .then(() => {
              this.disabled = false;
              this.$router.replace({ path: "/admin" });
            })
            .catch(err => {
              this.disabled = false;
              this.error = err.message;
            });
      }
    }
  }
}
</script>


<style>
.no-bg {
  background: none;
  border: none;
}

.button-white {
  background-color: white;
  color: black;
}

.font-roboto {
    font-family: 'Roboto-Medium';
    /*font-weight: 500;*/
  }

#app {
  background: url('../assets/img/ekran-logowania.png') no-repeat center center fixed;
}
</style>