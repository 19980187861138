module.exports = {
    save_your_time: 'Save your time',
    welcome: 'Welcome',
    hello: 'Hello',
    no_show_reduction: 'Reduce the number of no-shows',
    manage_reminders: 'Manage reminders like a pro from a simple and intuitive dashboard',
    do_you_know_that: 'Did you know?',
    app_description_short: 'An application for sending SMS reminders of Google Calendar events',
    more_about_product: 'More about the product',
    personal_assistant: 'Personal time assistant',
    personal_assistant_text: 'Notifyapp - a modern application for time management and sending event reminders via SMS. Be always on time!',
    forget_about_lateness: 'Forget about lateness',
    forget_about_lateness_text: 'With Notifyapp, you can avoid unnecessary delays and always be prepared for your meetings. Automated SMS reminders will help you be punctual.',
    less_no_shows: 'Fewer no-shows',
    less_no_shows_text: 'Notifyapp helps in reducing the number of missed appointments. With SMS reminders, you can quickly react and cancel a meeting if needed.',
    simple_use: 'Simple and intuitive to use',
    simple_use_text: 'Notifyapp - an easy-to-use application available for everyone. Connect with your Google account and start adding events. Automated SMS reminders work in the background.',
    brand_building: 'Brand building and customer reviews',
    brand_building_text: 'Notifyapp offers additional features that help in building your brand and collecting customer reviews. After the meeting, review requests are sent, which helps in developing your business.',
    get_addon: 'Get the addon',
    get_addon_text: 'Please select an extension and you will be automatically taken to Google Marketplace, where you can activate it. Once installed, our extensions will be automatically added to your calendar in each browser, all using the same SMS package.',
    get_addon_notify: '"Reduce no-shows!" Send automatic SMS appointment reminders to your clients directly from your Google calendar!',
    get_addon_opinion: 'Get more reviews on Google! Send automatic post-meeting feedback reminders via SMS to your customers directly from Google Calendar™',
    navigation_home: 'Home',
    navigation_product: 'Product',
    navigation_download: 'Download',
    navigation_pricing: 'Pricing',
    navigation_contact: 'Contact',
    navigation_login: 'Login',
    monthly_yearly: 'Monthly / Yearly',
    save_choosing_yearly_plan: 'Save 20% by choosing the yearly plan',
    subscribe: 'Subscribe',
    loading: 'Loading...',
    need_more: 'Need more?',
    contact_with_us: 'Contact us!',
    sms_per_month: 'SMS/month',
    no_calendar_limit: 'No calendar limit',
    no_user_limit: 'No user limit',
    contact: 'Contact',
    contact_text: 'Have questions? Get in touch with us.',
    we_answer_your_questions: 'We answer all your questions and concerns',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    send: 'Send',
    close: 'Close',
    name_is_required: 'Name is required',
    email_is_required: 'Email address is required',
    email_is_invalid: 'Email address is invalid',
    message_is_required: 'Message content is required',
    message_sent: 'Thank you for your message',
    message_not_sent: 'Error sending the message',
    terms: 'Terms',
    privacy_policy: 'Privacy Policy',
    users: 'Users',
    loading_please_wait: 'Loading, please wait...',
    add_user: 'Add user',
    user_email: 'User email',
    cancel: 'Cancel',
    save: 'Save',
    new_user: 'New user',
    edit_user: 'Edit user',
    account_has_been_deleted: 'Account has been deleted',
    account_could_not_be_deleted: 'Account could not be deleted',
    delete_item_are_you_sure: 'Are you sure you want to delete this item?',
    user_has_been_updated: 'User has been updated',
    user_has_not_been_updated: 'User has not been updated',
    user_has_been_added: 'User has been added',
    user_has_not_been_added: 'User has not been added',
    menu_dashboard: 'Dashboard',
    menu_users: 'Users',
    menu_plans: 'Plans',
    menu_settings: 'Settings',
    menu_addons: 'Addons',
    menu_support: "Support",
    loading_account_information: 'Loading account information...',
    max_11_chars: 'Maximum 11 characters',
    min_3_chars: 'Minimum 3 characters',
    invalid_alphaid: 'Invalid AlphaId. It must contain at least one letter. Allowed characters are: lowercase and uppercase letters, numbers, -, _, +, &, and space.',
    settings: 'Settings',
    subscribed_account: 'Subscribed account',
    subscription_cancel_at_period_end: 'Subscription will be canceled at the end of the billing period',
    current_period_start: 'Current billing period started',
    current_period_end: 'Current billing period ends',
    manage_subscription: 'Manage subscription',
    subscription_plans: 'Subscription plans',
    welcome_dialog_title: "Welcome bonus 10 SMS",
    welcome_dialog_subtitle:"Thank you for registering. We have added 10 SMS to your account. You can use them to test our service.",
    welcome_dialog_text: "Install our Google Calendar™ addon to try it out. Below you will find a link to the Google Workspace Marketplace.If you have any questions, please contact us at: ",
    back_to_home_page: "Back to home page",
    feel_free_to_ask: "If you have any questions feel free to ask. Please use the form below to contact us or write an email to support@notifyapp.cloud.",
    please_email_us: "Plese email us:",
    users_information: "Here, you have the option to add extra users to your team. These individuals will be automatically connected to your licenses and packages. They will have the same access to the application as you do, but remember, you will remain the administrator. If you do not add anyone only you will be able to send text messages to your customers.",
    menu_sender: 'Sender',
    sender_information: 'This settings lets you customize the name displayed as the sender of your SMS messages, replacing the phone number. Note that this option may be restricted in some countries due to legal regulations. However, where permitted, create a distinct sender name aligning with your brand. Enjoy the flexibility of conveying your message through a recognizable! Stay connected with a personalized touch.',
    sender_alphaid: 'SMS Sender Name',
    check_recipient_country: 'Check recipient country',
    check_recipient_country_info: 'Choose recipient country and check if custom sender name is available.',
    check_recipient_country_true: 'This country allows custom sender names.',
    check_recipient_country_false: 'This country does not allow custom sender names. The message sender will be the following number: +1 3608032010. If you are interested in a dedicated number, please contact us.',
    select_country: 'Select country',
    choose: "Choose",
    plans_info_no_test_sms: 'Test the application without purchasing the package! New users receive 10 free text messages for testing. If you have just registered, remember to refresh the Google Calendar™ addon.',
    dashboard_info: 'Welcome to your NotifyApp Dashboard! Here, you can view and manage all your important information at a glance. This includes the number of messages sent, scheduled messages, and your team members. Stay informed and keep track of your communication metrics.',
    dashboard_messages_amount: 'Your messages amount',
    dashboard_teammates: 'Your teammates',
    dashboard_scheduled_messages: 'Scheduled messages',
    dashboard_your_plan: 'Your plan',
    dashboard_free_sms: 'Free 10 SMS',
    dashboard_upgrade: 'Upgrade to a paid plan for more.',
    dashboard_last_messages: 'Last 10 messages',
    dashboard_next_messages: 'Next 10 messages',
    dashboard_date: 'Date',
    dashboard_recipient: 'Recipient',
    dashboard_next_invoice: 'Next invoice: ',
    dashboard_no_messages: 'No messages', 
};
  