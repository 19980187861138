<template>
  <section id="hero">
    <v-parallax
      dark
      :src="imgsrc"
      height="750">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" xl="8">
              <img src="@/assets/img/chmura.svg">
              <h1 class="display-2 font-weight-bold mb-4">Notifyapp</h1>
              <h1 class="font-weight-light">
                {{ $t('app_description_short') }}
              </h1>
              <!--
              <h2 class="font-weight-light text-subtitle-2" >Notifyapp.cloud to nowoczesna aplikacja, która służy do zarządzania czasem i wysyłania przypomnień o zbliżających się spotkaniach za pośrednictwem SMS-ów. Aplikacja działa jako dodatek do Kalendarza Google.Dzięki temu użytkownicy będą mogli otrzymywać przypomnienia SMS, które pozwolą im uniknąć spóźnień na ważne spotkania.</h2>
              -->
              <v-btn
                rounded
                outlined
                large
                dark
                @click="$vuetify.goTo('#features')"
                class="mt-5"
              >
              {{ $t('more_about_product') }}
                <v-icon class="ml-2">mdi-arrow-down</v-icon>
              </v-btn>

            </v-col>
            <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
      -->
    </v-parallax>
    <v-container fluid id="features" class="mt-16">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <!-- <v-col cols="12" class="text-center">
              <h1 class="font-weight-light display-2">Title</h1>
              <h1 class="font-weight-light">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </h1>
            </v-col> -->
            <v-carousel
              light
              :continuous="false"
              :cycle="true"
              :show-arrows="false"
              hide-delimiter-background
              height="500"
              >
              <v-carousel-item
              v-for="(feature, i) in features"
              :key="i"
              >
              <v-card
                  class="card"
                  shaped
                  :elevation="4"
                  style="max-width: 600px; margin: 0 auto;"
                >
                  <v-img
                    :src="feature.img"
                    max-width="220px"
                    class="d-block ml-auto mr-auto"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!--
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
    -->
  </section>
</template>

<script>

export default {
  
  data() {
    return {
      imgsrc: require("@/assets/img/Desktop3.png"),
      features : [
  {
    img: require("@/assets/img/personal-assistent.svg"),
    title: this.$t('personal_assistant'),
    text: this.$t('personal_assistant_text'),
  },
  {
    img: require("@/assets/img/forget-lates.svg"),
    title: this.$t('forget_about_lateness'),
    text: this.$t('forget_about_lateness_text'),
  },
  {
    img: require("@/assets/img/noshows-reduction.svg"),
    title: this.$t('less_no_shows'),
    text: this.$t('less_no_shows_text'),
  },
  {
    img: require("@/assets/img/simpleux.svg"),
    title: this.$t('simple_use'),
    text: this.$t('simple_use_text'),
  },
  {
    img: require("@/assets/img/brand-building.svg"),
    title: this.$t('brand_building'),
    text: this.$t('brand_building_text'),
  },
],

    };
  },
  created() {
        window.addEventListener('resize', () => {
          this.set_background()
        })
        this.set_background()
    },
  watch: {
  },
  methods: {
    set_background(){
      if (window.innerWidth < 800){
            this.imgsrc =  require("@/assets/img/grafika.png")
          } else {
            this.imgsrc =  require("@/assets/img/Desktop3.png")
        }
    }
  },
  
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>
