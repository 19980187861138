<template>
  <v-app style="background: linear-gradient(to left, rgb(100, 181, 246), rgb(144, 202, 249));">
    <h1>On this site you will find help about how to use NotifyApp. If you would have any further questions please contact us. Please write to support@notifyapp.cloud</h1>
    <img src="../assets/img/help1.png">
    <img src="../assets/img/help2.png">
  </v-app>
</template>

<script>
//import firebase from 'firebase/compat/app';

export default {
  data() {
  },
  methods: {
  },
}
</script>
  