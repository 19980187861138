<template>
  <v-app style="background: linear-gradient(to left, rgb(100, 181, 246), rgb(144, 202, 249));">
   <p> <strong>PRIVACY POLICY</strong> </p>
1. The controller of personal data of users of the tool that allows sending SMS text
messages reminding of appointments (hereinafter: the Add-on), being an add-on to
the Google Calendar application, within the meaning of legal regulations on personal
data protection shall be Michał Górski conducting business activity under the
business name ADG Michał Górski, at the address: ul. Lipowa 16D/U5, 81-572 Gdynia,
TAX ID No. 9570949817, Statistical No. (REGON): 220563135 (hereinafter: the
Controller).<br>
2. Personal data shall be processed on the basis of Regulation (EU) 2016/679 of the
European Parliament and of the Council of 27 April 2016 on the protection of natural
persons with regard to the processing of personal data and on the free movement of
such data, and repealing Directive 95/46/EC) (OJ EU 119 No. 1 of 2016) (hereinafter:
GDPR).<br>
3. Personal data will be processed for the purposes of:<br>
a) using the Add-on in accordance with its intended purpose - pursuant to Article
6(1)(b) and (c) GDPR;<br>
b) pursuing claims, taking actions of a debt collection nature pursuant to Article 6(1)
(c) and (f) GDPR.<br>
4. If the Controller receives information about the User's use of the Add-on contrary to
the Rules and Regulations or applicable legal provisions (unauthorized use), the
Controller may process the User's personal data also to the extent necessary to
determine the User's liability, provided that he records for evidence purposes the
fact of obtaining and the content of such data.<br>
5. The recipients of personal data will include entities cooperating with the Controller,
in particular:<br>
a) entities providing IT services to the Controller;<br>
b) legal advisers;<br>
c) entities providing services to the Controller for the purpose of data processing,
including marketing agencies.<br>
6. Personal data will be stored depending on the purpose for which it was collected:<br>
a) for the duration of using the Add-on;<br>
b) until a dispute is resolved, taking into account the limitation periods for claims - in
the case of data processing for the purpose of pursuing claims and taking actions of a
debt collection nature;<br>
c) until an objection is raised - in the case of personal data processing pursuant to
Article 6 (1)(f) GDPR;<br>
d) after the lapse of the periods indicated hereinabove, for the time that the law
requires the storage of data or for the period of limitation of any claims.<br>
7. Pursuant to the GDPR, the User has the following rights regarding his personal data:<br>
a) the right to access the content of the data;<br>
b) the right to rectify the content of the data;<br>
c) the right to delete the data;<br>
d) the right to limit data processing;<br>
e) the right to transfer the data;<br>
f) the right to object;<br>
g) the right to lodge a complaint with the President of the Personal Data Protection
Office or other competent supervisory authority;<br>
h) the right to withdraw consent to the processing of personal data at any time<br>
without affecting the lawfulness of the processing which was made on the basis of
consent before its withdrawal - in the event that the processing would take place on
the basis of consent.<br>
8. Providing personal data is voluntary; however, it constitutes a prerequisite to use the
Add-on.<br>
9. To provide and maintain our service, NotifyApp uses your Google account data as described below: <br>

  a) When you open a Google Calendar event, our service accesses the event ID to validate the reminder state for that specific event. Additionally, the service accesses the event details such as description, date, and time to pre-populate the app fields.
<br>
  b) If you create a reminder through our service, only the data filled in the app's form (not the calendar's data) will be sent to the third-party company responsible for sending the SMS reminder. This includes the sender's name, prefix or code, reminder's phone number, reminder's text, and reminder's date and time.
<br>
  c)  To provide you with the ability to check the state of past reminders and the option to cancel them by user request, we store the event ID from which a reminder has been set. We do not store any other data from your Google Calendar.
<br>
10. NotifyApp use and transfer to any other app of information received from Google APIs will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, including the Limited Use requirements.
</v-app>
</template>

<script>
//import firebase from 'firebase/compat/app';

export default {
  data() {
  },
  methods: {
  },
}
</script>
