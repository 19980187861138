<template>
    <div>
        <v-row>
  <v-col> 
    <v-card>
      <v-card-title>{{ $t('menu_dashboard') }}</v-card-title>
      <v-card-subtitle> {{ $t('dashboard_info') }} </v-card-subtitle>
    </v-card>
  </v-col>  
</v-row>
        <v-row>
            <v-col cols="6">
                <DashBoardCard :title="this.$t('dashboard_messages_amount')" :subtitle="'SMS'" color="primary" :data="sms_amount" 
                :icon="'mdi-message-processing'" />
                <DashBoardCard :title="this.$t('dashboard_scheduled_messages')" :subtitle="'SMS'" color="error" :data="futureMessagesCount.toString()" 
                :icon="'mdi-message-text-clock'" />

                <v-card class="mx-auto my-4" color="third" style="width: 100%;" outlined>
    <v-list-item three-line>
        <v-list-item-content>
            <div class="overline mb-4"><b>{{ $t('dashboard_last_messages')}}</b></div>
            <div v-if="last10Messages.length === 0">{{ $t('dashboard_no_messages') }}</div>
            <div v-else>
                <div style="display: flex; justify-content: space-between;">
                    <v-list-item-title style="margin: 5px;"><b>{{ $t('dashboard_recipient') }}</b></v-list-item-title>
                    <v-list-item-title style="margin: 5px;"><b>{{ $t('dashboard_date') }}</b></v-list-item-title>
                </div>
                <div v-for="(message, index) in last10Messages" :key="index" style="display: flex; justify-content: space-between;">
                    <v-list-item-title style="margin: 5px;">{{ message.to }}</v-list-item-title>
                    <v-list-item-subtitle style="margin: 5px;">{{ message.sendAt }}</v-list-item-subtitle>
                </div>
            </div>
        </v-list-item-content>
    </v-list-item>
</v-card>


            </v-col>
            <v-col cols="6">
                <DashBoardCard :title="this.$t('dashboard_teammates')" :subtitle="this.$t('users')" color="primary" :data="accounts_amount"
                :icon = "'mdi-account-group'" />
                <DashBoardCard v-if="subscription" :title="'Your plan'" :subtitle="subscription.items[0].price.product.name" color="error" 
                :data="this.$t('dashboard_next_invoice') + new Date(subscription.current_period_end.seconds * 1000).toLocaleDateString()"
                :icon = "'mdi-bank-circle-outline'" />
                <DashBoardCard v-else :title="this.$t('dashboard_your_plan')" :subtitle="this.$t('dashboard_free_sms')" color="error" :data="this.$t('dashboard_upgrade')"
                :icon = "'mdi-bank-circle-outline'" />
                
                <v-card class="mx-auto my-4" color="secondary" style="width: 100%;" outlined>
    <v-list-item three-line>
        <v-list-item-content>
            <div class="overline mb-4">{{ $t('dashboard_next_messages') }}</div>
            <div v-if="next10Messages.length === 0">{{ $t('dashboard_no_messages') }}</div>
            <div v-else>
                <div style="display: flex; justify-content: space-between;">
                    <v-list-item-title style="margin: 5px;"><b>{{ $t('dashboard_recipient') }}</b></v-list-item-title>
                    <v-list-item-title style="margin: 5px;"><b>{{ $t('dashboard_date') }}</b></v-list-item-title>
                </div>
                <div v-for="(message, index) in next10Messages" :key="index" style="display: flex; justify-content: space-between;">
                    <v-list-item-title style="margin: 5px;">{{ message.to }}</v-list-item-title>
                    <v-list-item-subtitle style="margin: 5px;">{{ message.sendAt }}</v-list-item-subtitle>
                </div>
            </div>
        </v-list-item-content>
    </v-list-item>
</v-card>


            </v-col>
        </v-row>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import DashBoardCard from '../../components/DashBoardCard';

import firebase from "firebase/compat";
import "firebase/compat/firestore";

function groupBy(array, key){
  const result = {}
  array.forEach(item => {
    if (!result[item[key]]){
      result[item[key]] = []
    }
    result[item[key]].push(item)
  })
  return result
}

export default {
    data() {
        return { 
            'sms_amount': '0/0',
            'accounts_amount': '0',
            'subscription': null
        }
    },
    components: {
        DashBoardCard,
    },
    async created() {
        await this.loadAccounts();
        await this.fetchSubscription();
        await this.fetchLast10Messages(this.user.data.uid);
        await this.fetchNext10Messages(this.user.data.uid);
        await this.fetchFutureMessagesCount(this.user.data.uid);

        if (this.sms.max !== undefined){
            this.sms_amount = `${this.sms.current}/${this.sms.max}`;
        }

        this.accounts_amount = this.accounts.length.toString();
    },
    methods: {
        ...mapActions('sms', ['fetchLast10Messages', 'fetchNext10Messages', 'fetchFutureMessagesCount']),
        ...mapActions({
            loadCategories: 'categories/loadCategories',
            loadAccounts: 'accounts/loadAccounts',
            loadSMS: 'accounts/loadSMS',
        }),
        async fetchSubscription() {
      //this.loading = true;

      this.subscription = await firebase.firestore()
        .collection("customers")
        .doc(this.user.data.uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active", "past_due", "unpaid"])
        .get()
        .then((sub) => sub.docs.length > 0 ? sub.docs[0].data() : null);
        }
    },
    computed: {
        ...mapGetters({
            categories: 'categories/getCategories',
            accounts: 'accounts/getAccounts',
            sms: 'accounts/getSMS',
            last10Messages: 'sms/last10Messages',
            next10Messages: 'sms/next10Messages',
            futureMessagesCount: 'sms/futureMessagesCount',
            user: 'auth/user'
        }),
        groups(){
            return groupBy(this.accounts, 'category_id')
        }
    }
}
</script>
