<template>
    <v-card :color="color" elevation="15" outlined style="margin-bottom: 5px;">
        <v-card-title class="white--text">{{ title }}</v-card-title>
        <v-card-title>
            <v-icon color="white" large left>{{ icon }}</v-icon>
            <span class="white--text">{{ data }}</span>
        </v-card-title>
        <v-card-subtitle class="white--text">
            {{ subtitle }}
        </v-card-subtitle>
    </v-card>
</template>

<script>
export default {
    props: {
        title: String,
        subtitle: String,
        color: String,
        data: String,
        icon: String
    }
}
</script>