module.exports = {
    save_your_time: '시간을 절약하세요',
    welcome: '환영합니다',
    hello: '안녕하세요',
    no_show_reduction: '미참여 회의 수 줄이기',
    manage_reminders: '간편하고 직관적인 대시보드로 전문가처럼 알림 관리하기',
    do_you_know_that: '알고 계신가요?',
    app_description_short: 'Google 캘린더에서 약속에 대한 SMS 알림을 보내는 앱',
    more_about_product: '제품에 대해 더 알아보기',
    personal_assistant: '개인 시간 비서',
    personal_assistant_text: 'Notifyapp - 시간 관리와 SMS 약속 알림을 위한 현대적인 앱입니다. 항상 시간을 지키세요!',
    forget_about_lateness: '지각을 잊어버리세요',
    forget_about_lateness_text: 'Notifyapp을 사용하면 불필요한 지각을 방지하고 항상 회의에 대비할 수 있습니다. 자동 SMS 알림으로 정시에 도착하세요.',
    less_no_shows: '불참 회의 감소',
    less_no_shows_text: 'Notifyapp은 불참 회의 수를 줄이는 데 도움을 줍니다. SMS 알림을 통해 신속하게 대응하고 필요한 경우 회의를 취소할 수 있습니다.',
    simple_use: '간편하고 직관적인 사용',
    simple_use_text: 'Notifyapp은 누구나 사용할 수 있는 사용자 친화적인 앱입니다. Google 계정과 연동하여 약속을 추가할 수 있습니다. 자동 SMS 알림은 백그라운드에서 작동합니다.',
    brand_building: '브랜드 구축과 고객 평가',
    brand_building_text: 'Notifyapp은 브랜드 구축 및 고객 평가를 돕는 추가 기능을 제공합니다. 회의 후에는 피드백 요청이 전송되어 비즈니스 성장을 돕습니다.',
    get_addon: '애드온 다운로드',
    get_addon_text: '확장 프로그램을 선택하면 자동으로 구글 마켓플레이스로 이동하여 활성화할 수 있습니다. 설치하면 각 브라우저의 달력에 자동으로 추가되며 동일한 SMS 패키지를 사용합니다.',
    get_addon_notify: '"결석을 줄이세요!" 구글 캘린더에서 고객에게 자동으로 SMS 예약 알림을 보내보세요!',
    get_addon_opinion: '구글에서 더 많은 리뷰를 받아보세요! 구글 캘린더에서 고객에게 자동으로 SMS 후속 회의 피드백 알림을 보내보세요!',
    navigation_home: '홈',
    navigation_product: '제품',
    navigation_download: '다운로드',
    navigation_pricing: '가격',
    navigation_contact: '문의',
    navigation_login: '로그인',
    monthly_yearly: '월간 / 연간',
    save_choosing_yearly_plan: '연간 요금제 선택으로 20% 절약하기',
    subscribe: '구독하기',
    loading: '로딩 중...',
    need_more: '더 필요하신가요?',
    contact_with_us: '문의하기!',
    sms_per_month: '월간 SMS',
    no_calendar_limit: '제한 없는 캘린더',
    no_user_limit: '제한 없는 사용자',
    contact: '문의',
    contact_text: '질문이 있으신가요? 연락 주세요.',
    we_answer_your_questions: '질문과 의문에 답변해 드립니다.',
    name: '이름',
    email: '이메일',
    message: '메시지',
    send: '보내기',
    close: '닫기',
    name_is_required: '이름을 입력해주세요',
    email_is_required: '이메일 주소를 입력해주세요',
    email_is_invalid: '유효하지 않은 이메일 주소입니다',
    message_is_required: '메시지를 입력해주세요',
    message_sent: '메시지를 보냈습니다',
    message_not_sent: '메시지를 보내는 중에 오류가 발생했습니다',
    terms: '약관',
    privacy_policy: '개인정보 처리방침',
    users: '사용자',
    loading_please_wait: '로딩 중, 잠시 기다려주세요...',
    add_user: '사용자 추가',
    user_email: '사용자 이메일',
    cancel: '취소',
    save: '저장',
    new_user: '새 사용자',
    edit_user: '사용자 편집',
    account_has_been_deleted: '계정이 삭제되었습니다',
    account_could_not_be_deleted: '계정을 삭제할 수 없습니다',
    delete_item_are_you_sure: '정말로 이 항목을 삭제하시겠습니까?',
    user_has_been_updated: '사용자가 업데이트되었습니다',
    user_has_not_been_updated: '사용자가 업데이트되지 않았습니다',
    user_has_been_added: '사용자가 추가되었습니다',
    user_has_not_been_added: '사용자가 추가되지 않았습니다',
    menu_dashboard: '대시보드',
    menu_users: '사용자',
    menu_plans: '요금제',
    menu_settings: '설정',
    menu_addons: '애드온',
    menu_support: '지원',
    loading_account_information: '계정 정보를 불러오는 중...',
    max_11_chars: '최대 11자까지 입력 가능합니다',
    min_3_chars: '최소 3자',
    invalid_alphaid: '유효하지 않은 알파아이디입니다. 최소한 한 글자의 문자를 포함해야 합니다. 소문자, 대문자, 숫자, -, _, +, & 및 공백이 허용됩니다.',
    settings: '설정',
    subscribed_account: '구독 중인 계정',
    subscription_cancel_at_period_end: '구독이 현재 기간 종료 시 취소됩니다',
    current_period_start: '현재 기간 시작',
    current_period_end: '현재 기간 종료',
    manage_subscription: '구독 관리',
    subscription_plans: '구독 요금제',
    welcome_dialog_title: "환영 보너스 10개의 SMS",
    welcome_dialog_subtitle: "등록해 주셔서 감사합니다. 계정에 10개의 SMS가 추가되었습니다. 당사의 서비스를 테스트하는 데 사용하실 수 있습니다.",
    welcome_dialog_text: "Google Calendar™ 애드온을 설치하여 사용해 보세요. 아래에서 Google Workspace Marketplace로 가는 링크를 찾으실 수 있습니다. 궁금한 점이 있으시면 아래 연락처로 문의해 주세요: ",
    back_to_home_page: "홈으로 돌아가기",
    feel_free_to_ask: "질문이 있으시면 자유롭게 물어보세요. 저희에게 연락하려면 아래 양식을 사용하거나 support@notifyapp.cloud로 이메일을 보내주시기 바랍니다.",
    please_email_us: "이메일로 문의해주세요:",
    users_information: "여기에서 팀에 추가 사용자를 추가할 수 있습니다. 이들은 자동으로 귀하의 라이선스와 패키지에 연결됩니다. 당신과 동일한 애플리케이션 접근 권한을 가지게 되지만, 기억해주세요. 여전히 관리자로 남게 됩니다. 아무도 추가하지 않으면 고객에게 텍스트 메시지를 보낼 수 있는 것은 오직 당신뿐입니다.",
    menu_sender: '송신자',
    sender_information: '이 설정을 통해 SMS 메시지의 발신자로 표시될 이름을 전화번호 대신 사용자 정의할 수 있습니다. 그러나 법적 규정으로 인해 일부 국가에서는 이 옵션이 제한될 수 있습니다. 허용되는 경우 브랜드에 맞는 구별 가능한 발신자 이름을 생성하세요. 알려진 발신자를 통해 메시지를 전달하는 유연성을 즐기세요! 개인적인 손길로 연결 유지하세요.',
    sender_alphaid: 'SMS 발신자 이름',
    check_recipient_country: '수령국 확인',
    check_recipient_country_info: '수령국을 선택하고 사용자 정의 발신자 이름이 가능한지 확인하세요.',
    check_recipient_country_true: '이 나라는 사용자 정의 발신자 이름을 허용합니다.',
    check_recipient_country_false: '이 나라는 사용자 정의 발신자 이름을 허용하지 않습니다. 메시지 발신자는 다음과 같은 번호가 될 것입니다: +1 3608032010. 별도의 전화번호에 관심이 있는 경우 저희에게 문의하세요.',
    select_country: '국가 선택',
    choose: "선택합니다",
    plans_info_no_test_sms: "패키지를 구매하지 않고 애플리케이션을 테스트하세요! 새로 가입한 사용자는 10개의 무료 텍스트 메시지를 받습니다. 방금 등록했다면 Google Calendar™ 애드온을 업데이트하는 것을 잊지 마세요.",
    dashboard_info: 'NotifyApp 대시보드에 오신 것을 환영합니다! 여기에서는 한눈에 중요한 정보를 모두 볼 수 있고 관리할 수 있습니다. 이에는 보낸 메시지 수, 예약된 메시지, 팀원 등이 포함됩니다. 항상 정보를 얻고 통신 지표를 추적하세요.',
    dashboard_messages_amount: '귀하의 메시지 수',
    dashboard_teammates: '당신의 팀원들',
    dashboard_scheduled_messages: '예약된 메시지',
    dashboard_your_plan: '귀하의 계획',
    dashboard_free_sms: '무료 10개의 SMS',
    dashboard_upgrade: '더 많은 것을 위해 유료 플랜으로 업그레이드하세요.',
    dashboard_last_messages: '마지막 10개의 메시지',
    dashboard_next_messages: '다음 10개의 메시지',
    dashboard_date: '날짜',
    dashboard_recipient: '수신자',
    dashboard_next_invoice: '다음 송장: ',
    dashboard_no_messages: '메시지 없음',
  };
  