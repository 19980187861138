<template>
<div>
  <div v-if="loading">{{ $t('loading_account_information') }}</div>
  <PriceSelection v-else-if="!subscription"/>
  <SubscribedAccount v-else :subscription="subscription"/>
  <div>
  <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">{{ $t('close') }}</v-btn>
      </template>
  </v-snackbar>
  </div>

</div>
</template>



<script>
import PriceSelection from "@/components/PriceSelection"
import SubscribedAccount from "@/components/SubscribedAccount"
import { mapGetters } from 'vuex'
import firebase from "firebase/compat";
import "firebase/compat/firestore";

export default {
  components: {
    PriceSelection,
    SubscribedAccount
  },
  data() {
    return {
      snack: false,
      snackColor: '',
      snackText: '',
      search: '',
      loading: true,
      dialog: false,
      subscription: null,
      stripeElement: '',
    }
  },
  mounted() {
    this.fetchSubscription();
  },
  computed: {
    ...mapGetters({
        accounts: 'accounts/getAccounts',
        categories: 'categories/getCategories',
        user: 'auth/user'
    }),
    billingTypeLabel() {
      return this.billingCycle ? "yearly" : "monthly";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
  },
  methods: {
    async fetchSubscription() {
      this.loading = true;

      this.subscription = await firebase.firestore()
        .collection("customers")
        .doc(this.user.data.uid)
        .collection("subscriptions")
        .where("status", "in", ["trialing", "active", "past_due", "unpaid"])
        .get()
        .then((sub) => 
            sub.docs.length > 0 ? sub.docs[0].data() : null);
      
    /* For test reason 
      this.subscription = {
        current_period_start:{seconds: 1688754136},
        current_period_end: {seconds: 1689854136}
      };
    /* end */

      this.loading = false;
    },
  }
};
</script>
