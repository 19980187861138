module.exports = {
    save_your_time: 'Ahorra tiempo',
    welcome: 'Bienvenido',
    hello: 'Hola',
    no_show_reduction: 'Reducción de la cantidad de ausencias',
    manage_reminders: 'Gestiona recordatorios como un profesional desde un panel simple e intuitivo',
    do_you_know_that: '¿Sabías que?',
    app_description_short: 'Aplicación para enviar recordatorios por SMS de citas del calendario de Google',
    more_about_product: 'Más sobre el producto',
    personal_assistant: 'Asistente personal del tiempo',
    personal_assistant_text: 'Notifyapp: una aplicación moderna para gestionar el tiempo y enviar recordatorios de citas a través de SMS. ¡Siempre llega puntual!',
    forget_about_lateness: 'Olvídate de los retrasos',
    forget_about_lateness_text: 'Con Notifyapp evitarás retrasos innecesarios y estarás siempre preparado para las citas. Los recordatorios automáticos por SMS te ayudarán a ser puntual.',
    less_no_shows: 'Menos citas sin asistir',
    less_no_shows_text: 'Notifyapp ayuda a reducir la cantidad de citas a las que no se asiste. Gracias a los recordatorios por SMS, podrás reaccionar rápidamente y cancelar una cita si es necesario.',
    simple_use: 'Uso sencillo e intuitivo',
    simple_use_text: 'Notifyapp: una aplicación fácil de usar disponible para todos. Conéctala a tu cuenta de Google y comienza a añadir citas. Los recordatorios automáticos por SMS funcionan en segundo plano.',
    brand_building: 'Construcción de marca y opiniones de los clientes',
    brand_building_text: 'Notifyapp ofrece funciones adicionales que ayudan a construir una marca y recopilar opiniones de los clientes. Después de una cita, se envían solicitudes de opinión para ayudar al desarrollo de la empresa.',
    get_addon: 'Obtener complemento',
    get_addon_text: 'Por favor, selecciona una extensión y serás redirigido automáticamente a Google Marketplace, donde podrás activarla. Una vez instaladas, nuestras extensiones se agregarán automáticamente a tu calendario en cada navegador, utilizando el mismo paquete de SMS.',
    get_addon_notify: '"¡Reduce las ausencias!" Envía recordatorios automáticos de citas por SMS a tus clientes directamente desde tu calendario de Google.',
    get_addon_opinion: '¡Obtén más opiniones en Google! Envía recordatorios automáticos de retroalimentación posterior a la reunión por SMS a tus clientes directamente desde el Calendario de Google™.',
    navigation_home: 'Inicio',
    navigation_product: 'Producto',
    navigation_download: 'Descargar',
    navigation_pricing: 'Precios',
    navigation_contact: 'Contacto',
    navigation_login: 'Iniciar sesión',
    monthly_yearly: 'Mensual / Anual',
    save_choosing_yearly_plan: 'Ahorra un 20% al elegir el plan anual',
    subscribe: 'Suscribirse',
    loading: 'Cargando...',
    need_more: '¿Necesitas más?',
    contact_with_us: 'Contáctanos',
    sms_per_month: 'SMS por mes',
    no_calendar_limit: 'Sin límite de calendarios',
    no_user_limit: 'Sin límite de usuarios',
    contact: 'Contacto',
    contact_text: '¿Tienes preguntas? Ponte en contacto con nosotros.',
    we_answer_your_questions: 'Respondemos a todas tus preguntas e inquietudes',
    name: 'Nombre',
    email: 'Correo electrónico',
    message: 'Mensaje',
    send: 'Enviar',
    close: 'Cerrar',
    name_is_required: 'Se requiere el nombre',
    email_is_required: 'Se requiere el correo electrónico',
    email_is_invalid: 'El correo electrónico es inválido',
    message_is_required: 'Se requiere el mensaje',
    message_sent: 'Gracias por tu mensaje',
    message_not_sent: 'Error al enviar el mensaje',
    terms: 'Términos y condiciones',
    privacy_policy: 'Política de privacidad',
    users: 'Usuarios',
    loading_please_wait: 'Cargando, por favor espera...',
    add_user: 'Agregar usuario',
    user_email: 'Correo electrónico del usuario',
    cancel: 'Cancelar',
    save: 'Guardar',
    new_user: 'Nuevo usuario',
    edit_user: 'Editar usuario',
    account_has_been_deleted: 'La cuenta ha sido eliminada',
    account_could_not_be_deleted: 'No se pudo eliminar la cuenta',
    delete_item_are_you_sure: '¿Estás seguro de que deseas eliminar este elemento?',
    user_has_been_updated: 'El usuario ha sido actualizado',
    user_has_not_been_updated: 'El usuario no ha sido actualizado',
    user_has_been_added: 'El usuario ha sido agregado',
    user_has_not_been_added: 'El usuario no ha sido agregado',
    menu_dashboard: 'Panel',
    menu_users: 'Usuarios',
    menu_plans: 'Planes',
    menu_settings: 'Configuración',
    menu_addons: 'Complementos',
    menu_support: "Soporte",
    loading_account_information: 'Cargando información de la cuenta...',
    max_11_chars: 'Máximo 11 caracteres',
    min_3_chars: 'Mínimo 3 caracteres',
    invalid_alphaid: 'ID de remitente alfanumérico no válido. Debe contener al menos una letra. Se permiten letras mayúsculas, letras minúsculas, números, -, _, +, & y espacios.',
    settings: 'Configuración',
    subscribed_account: 'Cuenta suscrita',
    subscription_cancel_at_period_end: 'La suscripción se cancelará al final del período de facturación',
    current_period_start: 'El período de facturación actual comenzó el',
    current_period_end: 'El período de facturación actual finaliza el',
    manage_subscription: 'Administrar suscripción',
    subscription_plans: 'Planes de suscripción',
    welcome_dialog_title: "Bono de bienvenida: 10 SMS",
    welcome_dialog_subtitle: "Gracias por registrarte. Hemos añadido 10 SMS a tu cuenta. Puedes usarlos para probar nuestro servicio.",
    welcome_dialog_text: "Instala nuestra extensión de Google Calendar™ para probarla. A continuación, encontrarás un enlace a Google Workspace Marketplace. Si tienes alguna pregunta, contáctanos en ",
    back_to_home_page: "Volver a la página de inicio",
    feel_free_to_ask: "Si tienes alguna pregunta, no dudes en preguntarnos. Utiliza el formulario que aparece a continuación para contactarnos o escribe un correo electrónico a support@notifyapp.cloud.",
    please_email_us: "Por favor, envíanos un correo electrónico a:",
    users_information: "Aquí tienes la opción de agregar usuarios adicionales a tu equipo. Estas personas estarán automáticamente conectadas a tus licencias y paquetes. Tendrán el mismo acceso a la aplicación que tú, pero recuerda que tú seguirás siendo el administrador. Si no añades a nadie, solo podrás enviar mensajes de texto a tus clientes.",
    menu_sender: 'Remitente',
    sender_information: 'Estas configuraciones te permiten personalizar el nombre que se muestra como remitente de tus mensajes SMS, en lugar del número de teléfono. Ten en cuenta que esta opción puede estar restringida en algunos países debido a regulaciones legales. Sin embargo, donde esté permitido, crea un nombre distintivo de remitente que se alinee con tu marca. ¡Disfruta de la flexibilidad de transmitir tu mensaje a través de un remitente reconocible! Mantente conectado con un toque personalizado.',
    sender_alphaid: 'Nombre del remitente SMS',
    check_recipient_country: 'Verificar país del destinatario',
    check_recipient_country_info: 'Seleccione el país del destinatario y verifique si está disponible el nombre de remitente personalizado.',
    check_recipient_country_true: 'Este país permite nombres de remitente personalizados.',
    check_recipient_country_false: 'Este país no permite nombres de remitente personalizados. El remitente del mensaje será el siguiente número: +1 3608032010. Si estás interesado en un número dedicado, contáctanos.',
    select_country: 'Seleccionar país',
    choose: "Elijo",
    plans_info_no_test_sms: "¡Prueba la aplicación sin comprar el paquete! Los nuevos usuarios reciben 10 mensajes de texto gratuitos para pruebas. Si te acabas de registrar, recuerda actualizar el complemento de Google Calendar™.",
    dashboard_info: '¡Bienvenido a tu Panel de NotifyApp! Aquí, puedes ver y administrar toda tu información importante de un vistazo. Esto incluye el número de mensajes enviados, mensajes programados y tus miembros del equipo. Mantente informado y sigue tus métricas de comunicación.',
    dashboard_messages_amount: 'Tu cantidad de mensajes',
    dashboard_teammates: 'Tus compañeros de equipo',
    dashboard_scheduled_messages: 'Mensajes programados',
    dashboard_your_plan: 'Tu plan',
    dashboard_free_sms: '10 SMS gratis',
    dashboard_upgrade: 'Actualiza a un plan de pago para más.',
    dashboard_last_messages: 'Últimos 10 mensajes',
    dashboard_next_messages: 'Próximos 10 mensajes',
    dashboard_date: 'Fecha',
    dashboard_recipient: 'Destinatario',
    dashboard_next_invoice: 'Próxima factura: ',
    dashboard_no_messages: 'No hay mensajes',
  };
  