module.exports = {
    save_your_time: 'Bespaar tijd',
    welcome: 'Welkom',
    hello: 'Hallo',
    no_show_reduction: 'Verminder het aantal gemiste afspraken',
    manage_reminders: 'Beheer herinneringen als een professional via een eenvoudig en intuïtief dashboard',
    do_you_know_that: 'Weet je dat?',
    app_description_short: 'Een app om sms-herinneringen te sturen voor afspraken in de Google Agenda',
    more_about_product: 'Meer over het product',
    personal_assistant: 'Persoonlijke tijdassistent',
    personal_assistant_text: 'Notifyapp - een moderne app voor tijdbeheer en het verzenden van afspraakherinneringen via sms. Wees altijd op tijd!',
    forget_about_lateness: 'Vergeet de vertragingen',
    forget_about_lateness_text: 'Met Notifyapp voorkom je onnodige vertragingen en ben je altijd voorbereid op een afspraak. Automatische sms-herinneringen helpen je op tijd te zijn.',
    less_no_shows: 'Minder gemiste afspraken',
    less_no_shows_text: 'Notifyapp helpt bij het verminderen van het aantal gemiste afspraken. Met sms-herinneringen kun je snel reageren en een afspraak annuleren indien nodig.',
    simple_use: 'Eenvoudig en intuïtief gebruik',
    simple_use_text: 'Notifyapp - een gebruiksvriendelijke app die voor iedereen toegankelijk is. Koppel het met je Google-account en begin met het toevoegen van afspraken. Automatische sms-herinneringen werken op de achtergrond.',
    brand_building: 'Merkopbouw en klantbeoordelingen',
    brand_building_text: 'Notifyapp biedt extra functies die helpen bij het opbouwen van je merk en het verzamelen van klantbeoordelingen. Na een afspraak worden verzoeken om feedback verzonden, wat helpt bij het laten groeien van je bedrijf.',
    get_addon: 'Add-on downloaden',
    get_addon_text: 'Selecteer alstublieft een extensie en u wordt automatisch naar de Google Marketplace geleid, waar u deze kunt activeren. Zodra geïnstalleerd, worden onze extensies automatisch toegevoegd aan uw kalender in elke browser, met gebruik van hetzelfde SMS-pakket.',
    get_addon_notify: '"Verminder no-shows!" Verstuur automatische SMS-afspraakherinneringen naar uw klanten rechtstreeks vanuit uw Google-agenda!',
    get_addon_opinion: 'Verkrijg meer beoordelingen op Google! Stuur automatische post-meeting feedbackherinneringen via SMS naar uw klanten rechtstreeks vanuit de Google-agenda™.',
    navigation_home: 'Home',
    navigation_product: 'Product',
    navigation_download: 'Downloaden',
    navigation_pricing: 'Prijzen',
    navigation_contact: 'Contact',
    navigation_login: 'Inloggen',
    monthly_yearly: 'Maandelijks / Jaarlijks',
    save_choosing_yearly_plan: 'Bespaar 20% door te kiezen voor het jaarabonnement',
    subscribe: 'Abonneren',
    loading: 'Laden...',
    need_more: 'Meer nodig?',
    contact_with_us: 'Neem contact met ons op!',
    sms_per_month: 'SMS/maand',
    no_calendar_limit: 'Geen limiet op kalenders',
    no_user_limit: 'Geen limiet op gebruikers',
    contact: 'Contact',
    contact_text: 'Heb je vragen? Neem contact met ons op.',
    we_answer_your_questions: 'We beantwoorden al je vragen en zorgen',
    name: 'Naam',
    email: 'E-mail',
    message: 'Bericht',
    send: 'Verstuur',
    close: 'Sluiten',
    name_is_required: 'Naam is verplicht',
    email_is_required: 'E-mailadres is verplicht',
    email_is_invalid: 'Ongeldig e-mailadres',
    message_is_required: 'Bericht is verplicht',
    message_sent: 'Bedankt voor je bericht',
    message_not_sent: 'Bericht kon niet worden verzonden',
    terms: 'Voorwaarden',
    privacy_policy: 'Privacybeleid',
    users: 'Gebruikers',
    loading_please_wait: 'Laden, even geduld...',
    add_user: 'Gebruiker toevoegen',
    user_email: 'Gebruikers-e-mail',
    cancel: 'Annuleren',
    save: 'Opslaan',
    new_user: 'Nieuwe gebruiker',
    edit_user: 'Gebruiker bewerken',
    account_has_been_deleted: 'Account is verwijderd',
    account_could_not_be_deleted: 'Account kon niet worden verwijderd',
    delete_item_are_you_sure: 'Weet je zeker dat je dit item wilt verwijderen?',
    user_has_been_updated: 'Gebruiker is bijgewerkt',
    user_has_not_been_updated: 'Gebruiker is niet bijgewerkt',
    user_has_been_added: 'Gebruiker is toegevoegd',
    user_has_not_been_added: 'Gebruiker is niet toegevoegd',
    menu_dashboard: 'Dashboard',
    menu_users: 'Gebruikers',
    menu_plans: 'Abonnementen',
    menu_settings: 'Instellingen',
    menu_addons: 'Add-ons',
    menu_support: 'Ondersteuning',
    loading_account_information: 'Accountinformatie laden...',
    max_11_chars: 'Maximaal 11 tekens',
    min_3_chars: 'Minimaal 3 tekens',
    invalid_alphaid: 'Ongeldige Alphaid. Moet minimaal één letter bevatten. Kleine letters, hoofdletters, cijfers, -, _, +, & en spatie zijn toegestaan.',
    settings: 'Instellingen',
    subscribed_account: 'Abonnement actief',
    subscription_cancel_at_period_end: 'Abonnement wordt aan het einde van de huidige periode geannuleerd',
    current_period_start: 'Huidige periode start',
    current_period_end: 'Huidige periode eindigt',
    manage_subscription: 'Beheer abonnement',
    subscription_plans: 'Abonnementsplannen',
    welcome_dialog_title: "Welkomstbonus: 10 SMS",
    welcome_dialog_subtitle: "Bedankt voor uw registratie. We hebben 10 SMS-berichten aan uw account toegevoegd. U kunt ze gebruiken om onze service te testen.",
    welcome_dialog_text: "Installeer onze Google Calendar™-add-on om het uit te proberen. Hieronder vindt u een link naar de Google Workspace Marketplace. Als u vragen heeft, neem dan contact met ons op via: ",
    back_to_home_page: "Terug naar de startpagina",
    feel_free_to_ask: "Als u vragen heeft, aarzel dan niet om ze te stellen. Gebruik het onderstaande formulier om contact met ons op te nemen of stuur een e-mail naar support@notifyapp.cloud.",
    please_email_us: "Stuur ons alsjeblieft een e-mail:",
    users_information: "Hier heb je de mogelijkheid om extra gebruikers aan je team toe te voegen. Deze personen worden automatisch gekoppeld aan je licenties en pakketten. Ze hebben dezelfde toegang tot de applicatie als jij, maar onthoud dat jij de beheerder blijft. Als je niemand toevoegt, kun jij als enige tekstberichten naar je klanten sturen.",
    menu_sender: 'Afzender',
    sender_information: 'Deze instellingen stellen u in staat om de naam aan te passen die wordt weergegeven als afzender van uw SMS-berichten, in plaats van het telefoonnummer. Houd er echter rekening mee dat deze optie in sommige landen beperkt kan zijn vanwege wettelijke voorschriften. Indien toegestaan, maak een onderscheidende afzendernaam die overeenkomt met uw merk. Geniet van de flexibiliteit om uw boodschap over te brengen via een herkenbare afzender! Blijf verbonden met een persoonlijke touch.',
    sender_alphaid: 'SMS Afzender Naam',
    check_recipient_country: 'Controleer land ontvanger',
    check_recipient_country_info: 'Selecteer het land van de ontvanger en controleer of een aangepaste afzender naam beschikbaar is.',
    check_recipient_country_true: 'Dit land staat aangepaste afzender namen toe.',
    check_recipient_country_false: 'Dit land staat geen aangepaste afzender namen toe. De afzender van het bericht zal het volgende nummer zijn: +1 3608032010. Als u geïnteresseerd bent in een toegewijd nummer, neem dan contact met ons op.',
    select_country: 'Selecteer land',
    choose: "Kiezen",
    plans_info_no_test_sms: "Test de applicatie zonder het pakket te kopen! Nieuwe gebruikers ontvangen 10 gratis sms-berichten voor testdoeleinden. Als je net geregistreerd bent, vergeet dan niet om de Google Calendar™-add-on te vernieuwen.",
    dashboard_info: 'Welkom bij uw NotifyApp Dashboard! Hier kunt u al uw belangrijke informatie in één oogopslag bekijken en beheren. Dit omvat het aantal verzonden berichten, geplande berichten en uw teamleden. Blijf op de hoogte en houd uw communicatiestatistieken bij.',
    dashboard_messages_amount: 'Uw aantal berichten',
    dashboard_teammates: 'Uw teamleden',
    dashboard_scheduled_messages: 'Geplande berichten',
    dashboard_your_plan: 'Uw plan',
    dashboard_free_sms: 'Gratis 10 SMS',
    dashboard_upgrade: 'Upgrade naar een betaald plan voor meer.',
    dashboard_last_messages: 'Laatste 10 berichten',
    dashboard_next_messages: 'Volgende 10 berichten',
    dashboard_date: 'Datum',
    dashboard_recipient: 'Ontvanger',
    dashboard_next_invoice: 'Volgende factuur: ',
    dashboard_no_messages: 'Geen berichten',
  };
  