<template>
  <div >
    <v-row>
      <v-col>
    <v-card>
    <v-card-title>{{ $t('subscribed_account') }}</v-card-title>
    
    <v-card-text>
      <div class="alert alert-warning" v-if="subscription.cancel_at_period_end">
        {{ $t('subscription_cancel_at_period_end') }}
      </div>
      <p>
        {{ $t('current_period_start') }}
        {{
          new Date(subscription.current_period_start.seconds * 1000).toLocaleString()
        }}
      </p>

      <p>
        {{ $t('current_period_end') }}
        {{
          new Date(subscription.current_period_end.seconds * 1000).toLocaleString()
        }}
      </p>
    </v-card-text>

    <v-card-actions>
      <v-btn :disabled="isLoading" @click="openCustomerPortal">
        {{ isLoading ? $t('loading') : $t('manage_subscription') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-col>
</v-row>

  </div>
</template>

<script>

import firebase  from "firebase/compat/app";

export default {
  props: ["subscription"],

  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    async openCustomerPortal() {
      this.isLoading = true;

      const functionRef = 
      firebase
        .app()
        .functions('europe-central2') // <-- need to specify correct location.
        .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
  
      const { data } = await functionRef({
        returnUrl: window.location.href,
      });

      window.location.assign(data.url);
    },
  },
}
</script>